import OilGas1 from './../Images/oil-gas1.png';

// import OilGas2 from 'assets/Img/oil-gas2.png';


const ProductsData =[
    {
        id:1,
        Data:{
            p1:"“Bedi Group” is proud to present the “Biryani Bar” franchise, a thriving chain of restaurants based in North America. We specialize in serving delectable biryani, a beloved dish known for its rich flavors and aromatic spices. ",
            p2:"With numerous restaurants operating successfully, we have established a strong presence in the food industry. Our commitment to quality and authenticity has earned us a loyal customer base and a reputation for excellence.",
            p3:"“Biryani Bar” is more than just a restaurant; it’s a culinary experience that brings people together.",
            p4:"These developments aimed to contribute to the growth and success of our company..",
            image1:"assets/website-images/Oil-and-Gas-pics/biryanibar.jpg",
            image2:"assets/website-images/Restaurants/london.jpeg",
        },
        service:"London [Biryani Bar]",
        product:"Biryani",
        category:"oil-and-gas",
        image:"assets/website-images/Oil-and-Gas-pics/biryanibar.jpg",
        span:"Expanding the Biryani Bar franchise, Bedi Group introduces authentic biryani offerings to new locations, enriching dining experiences.",
    },
    {
        id:2,
        Data:{
            p1:"“Bedi Group” is proud to present the “Biryani Bar” franchise, a thriving chain of restaurants based in North America. We specialize in serving delectable biryani, a beloved dish known for its rich flavors and aromatic spices. ",
            p2:"With numerous restaurants operating successfully, we have established a strong presence in the food industry. Our commitment to quality and authenticity has earned us a loyal customer base and a reputation for excellence.",
            p3:"“Biryani Bar” is more than just a restaurant; it’s a culinary experience that brings people together.",
            p4:"These developments aimed to contribute to the growth and success of our company..",
            image1:"assets/website-images/Oil-and-Gas-pics/biryanibar.jpg",
            image2:"assets/website-images/Restaurants/brantford.jpeg",
        },
        service:"Brantford [Biryani Bar]",
        product:"Biryani",
        category:"oil-and-gas",
        image:"assets/website-images/Oil-and-Gas-pics/biryanibar.jpg",
        span:"Expanding the Biryani Bar franchise, Bedi Group introduces authentic biryani offerings to new locations, enriching dining experiences.",
    },
    {
        id:3,
        Data:{
            p1:"“Bedi Group” is proud to present the “Biryani Bar” franchise, a thriving chain of restaurants based in North America. We specialize in serving delectable biryani, a beloved dish known for its rich flavors and aromatic spices. ",
            p2:"With numerous restaurants operating successfully, we have established a strong presence in the food industry. Our commitment to quality and authenticity has earned us a loyal customer base and a reputation for excellence.",
            p3:"“Biryani Bar” is more than just a restaurant; it’s a culinary experience that brings people together.",
            p4:"These developments aimed to contribute to the growth and success of our company..",
            image1:"assets/website-images/Oil-and-Gas-pics/biryanibar.jpg",
            image2:"assets/website-images/Restaurants/waterloo.jpeg",
        },
        service:" Waterloo [Biryani Bar]",
        product:"Biryani",
        category:"oil-and-gas",
        image:"assets/website-images/Oil-and-Gas-pics/biryanibar.jpg",
        span:"Expanding the Biryani Bar franchise, Bedi Group introduces authentic biryani offerings to new locations, enriching dining experiences.",
    },
    {
        id:4,
        Data:{
            p1:"“Bedi Group” is proud to present the “Biryani Bar” franchise, a thriving chain of restaurants based in North America. We specialize in serving delectable biryani, a beloved dish known for its rich flavors and aromatic spices. ",
            p2:"With numerous restaurants operating successfully, we have established a strong presence in the food industry. Our commitment to quality and authenticity has earned us a loyal customer base and a reputation for excellence.",
            p3:"“Biryani Bar” is more than just a restaurant; it’s a culinary experience that brings people together.",
            p4:"These developments aimed to contribute to the growth and success of our company..",
            image1:"assets/website-images/Oil-and-Gas-pics/biryanibar.jpg",
            image2:"assets/website-images/Restaurants/windsor.jpeg",
        },
        service:"Windsor [Biryani Bar]",
        product:"Biryani",
        category:"oil-and-gas",
        image:"assets/website-images/Oil-and-Gas-pics/biryanibar.jpg",
        span:"Expanding the Biryani Bar franchise, Bedi Group introduces authentic biryani offerings to new locations, enriching dining experiences.",
    },
    {
        id:5,
        Data:{
            p1:"“Bedi Group” is proud to present the “Biryani Bar” franchise, a thriving chain of restaurants based in North America. We specialize in serving delectable biryani, a beloved dish known for its rich flavors and aromatic spices. ",
            p2:"With numerous restaurants operating successfully, we have established a strong presence in the food industry. Our commitment to quality and authenticity has earned us a loyal customer base and a reputation for excellence.",
            p3:"“Biryani Bar” is more than just a restaurant; it’s a culinary experience that brings people together.",
            p4:"These developments aimed to contribute to the growth and success of our company..",
            image1:"assets/website-images/Oil-and-Gas-pics/biryanibar.jpg",
            image2:"assets/website-images/Restaurants/scarborough.jpeg",
        },
        service:"Scarbrough [Biryani Bar]",
        product:"Biryani",
        category:"oil-and-gas",
        image:"assets/website-images/Oil-and-Gas-pics/biryanibar.jpg",
        span:"Expanding the Biryani Bar franchise, Bedi Group introduces authentic biryani offerings to new locations, enriching dining experiences.",
    },
    {
        id:6,
        Data:{
            p1:"“Bedi Group” is proud to present the “Biryani Bar” franchise, a thriving chain of restaurants based in North America. We specialize in serving delectable biryani, a beloved dish known for its rich flavors and aromatic spices. ",
            p2:"With numerous restaurants operating successfully, we have established a strong presence in the food industry. Our commitment to quality and authenticity has earned us a loyal customer base and a reputation for excellence.",
            p3:"“Biryani Bar” is more than just a restaurant; it’s a culinary experience that brings people together.",
            p4:"These developments aimed to contribute to the growth and success of our company..",
            image1:"assets/website-images/Oil-and-Gas-pics/biryanibar.jpg",
            image2:"assets/website-images/Restaurants/angus.jpg",
        },
        service:"Angus [Biryani Bar]",
        product:"Biryani",
        category:"oil-and-gas",
        image:"assets/website-images/Oil-and-Gas-pics/biryanibar.jpg",
        span:"Expanding the Biryani Bar franchise, Bedi Group introduces authentic biryani offerings to new locations, enriching dining experiences.",
    },
    {
        id:7,
        Data:{
            p1:"“Bedi Group” is proud to present the “Biryani Bar” franchise, a thriving chain of restaurants based in North America. We specialize in serving delectable biryani, a beloved dish known for its rich flavors and aromatic spices. ",
            p2:"With numerous restaurants operating successfully, we have established a strong presence in the food industry. Our commitment to quality and authenticity has earned us a loyal customer base and a reputation for excellence.",
            p3:"“Biryani Bar” is more than just a restaurant; it’s a culinary experience that brings people together.",
            p4:"These developments aimed to contribute to the growth and success of our company..",
            image1:"assets/website-images/Oil-and-Gas-pics/biryanibar.jpg",
            image2:"assets/website-images/Restaurants/Cambridge.jpeg",
        },
        service:"Cambridge [Biryani Bar]",
        product:"Biryani",
        category:"oil-and-gas",
        image:"assets/website-images/Oil-and-Gas-pics/biryanibar.jpg",
        span:"Expanding the Biryani Bar franchise, Bedi Group introduces authentic biryani offerings to new locations, enriching dining experiences.",
    },
    // {
    //     id:6,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"bim",
    //     image:"assets/website-images/BIM-pics/bim1.png"
    // },
    // {
    //     id:7,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"bim",
    //     image:"assets/website-images/BIM-pics/bim2.png"
    // },
    // {
    //     id:8,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"bim",
    //     image:"assets/website-images/BIM-pics/bim3.png"
    // },
    // {
    //     id:9,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"bim",
    //     image:"assets/website-images/BIM-pics/bim4.png"
    // },
    // {
    //     id:10,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/BIM-pics/bim5.png"
    // },
    // {
    //     id:11,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/BIM-pics/bim6.png"
    // },
    // {
    //     id:12,
    //     service:"PEB Shed at Angola",
    //     product:"PEB-Shed-at-Angola",
    //     category:"peb-structures",
    //     image:"assets/website-images/Industrial-PEB-Projects/04_PEB-Shed-at-Angola.jpeg"
    // },
    // {
    //     id:13,
    //     service:"PEB",
    //     product:"PEB Structure",
    //     category:"peb-structures",
    //     image:"assets/website-images/Industrial-PEB-Projects/peb1.jpg"
    // },
    // {
    //     id:14,
    //     service:"PEB Shed at Odisha",
    //     product:"PEB-Shed-at-Angola",
    //     category:"peb-structures",
    //     image:"assets/website-images/Industrial-PEB-Projects/PEB-Shed-at-Odhisa.jpg"
    // },
    // {
    //     id:15,
    //     service:"PEB Shed at Malad",
    //     product:"PEB-Shed-at-Angola",
    //     category:"peb-structures",
    //     image:"assets/website-images/Industrial-PEB-Projects/Shed-at-malad.jpeg"
    // },
    // {
    //     id:16,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate1.jpeg"
    // },
    // {
    //     id:17,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/Bungalow-@-Yavatamal.jpg"
    // },
    // {
    //     id:18,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/Bungalow-@-Yavatamal2.jpg"
    // },
    // {
    //     id:19,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/Bungalow-@-Yavatamal1.jpg"
    // },
    // {
    //     id:20,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/Bungalow-@-Yavatamal2.jpg"
    // },
    // {
    //     id:21,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate.jpeg"
    // },
    // {
    //     id:22,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate2.jpeg"
    // },
    // {
    //     id:23,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate3.jpeg"
    // },
    // {
    //     id:24,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate4.jpg"
    // },
    // {
    //     id:25,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate5.jpg"
    // },
    // {
    //     id:26,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/shams-residency.jpg"
    // },
    // {
    //     id:27,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate6.jpeg"
    // },

    // // .........................Audit
    // {
    //     id:28,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test.jpeg"
    // },
   
    // {
    //     id:29,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test1.jpeg"
    // },
   
    // {
    //     id:30,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test2.jpeg"
    // },
   
    // {
    //     id:31,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test3.jpeg"
    // },
   
    // {
    //     id:32,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test4.jpeg"
    // },
   
    // {
    //     id:33,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test5.jpeg"
    // },
  
    // {
    //     id:26,
    //     service:"Oxygen Plant at Mahul Chembur",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Industrial-PEB-Projects/Oxygen-Plant-at-Mahul-Chembur.png"
    // },

    // sus
    // {
    //     id:34,
    //     service:"sus",
    //     product:"sus",
    //     category:"sus",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate1.jpeg"
    // },

    // {
    //     id:35,
    //     service:"sus",
    //     product:"sus",
    //     category:"sus",
    //     image:"assets/website-images/Industrial-PEB-Projects/peb1.jpg"
    // },
    // {
    //     id:36,
    //     service:"BLUH BLUH",
    //     product:"Ammonia plant",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/Oil-and-Gas-pics/ammonia-plant.jpg"
    // },
    // {
    //     id:37,
    //     service:"BLUH BLUH",
    //     product:"BLUR BLUR",
    //     category:"Franchise",
    //     image:"assets/website-images/Oil-and-Gas-pics/IOCL-Assam.jpg"
    // },
    // {
    //     id:38,
    //     service:"BLUH BLUH",
    //     product:"BLUR BLUR",
    //     category:"Franchise",
    //     image:"assets/website-images/Oil-and-Gas-pics/IOCL-Assam-01.jpg"
    // },
    // {
    //     id:39,
    //     service:"BLUH BLUH",
    //     product:"BLUR BLUR",
    //     category:"Franchise",
    //     image:"assets/website-images/Oil-and-Gas-pics/IOCL-project-pic.jpg"

];
export default ProductsData;