import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import "../../Components/CustomCarousal/index.scss";
// import { structuredData } from './data';
import biryanibar from "../../Images/peb/biryanibar.jpg";


import structureImage from "../../Images/peb/intel slider images/1.jpg";
import structureImage2 from "../../Images/peb/intel slider images/2.jpg";
import structureImage3 from "../../Images/peb/intel slider images/3.jpg";
import structureImage4 from "../../Images/peb/intel slider images/4.jpg";
import structureImage5 from "../../Images/peb/intel slider images/5.jpg";
import structureImage6 from "../../Images/peb/intel slider images/6.jpg";
import structureImage7 from "../../Images/peb/intel slider images/7.jpg";
import structureImage8 from "../../Images/peb/intel slider images/8.jpg";
import structureImage9 from "../../Images/peb/intel slider images/9.jpg";
import structureImage10 from "../../Images/peb/intel slider images/10.jpg";
import structureImage11 from "../../Images/peb/intel slider images/11.jpg";

export const slidesData = [
  {
    title: "BrantFord",
    content:
    "we provide warm hospitality that embodies the spirit of South Asian culture. Our friendly staff members go above and beyond to ensure that guests feel welcomed and cared for, creating a memorable dining atmosphere that keeps patrons coming back for more.",
    image: biryanibar,
  },
  {
    title: "Wateriod",
    content:
    "we provide warm hospitality that embodies the spirit of South Asian culture. Our friendly staff members go above and beyond to ensure that guests feel welcomed and cared for, creating a memorable dining atmosphere that keeps patrons coming back for more.",
    image: biryanibar,
  },
  {
    title: "Scarbrough",
    content:
    "we provide warm hospitality that embodies the spirit of South Asian culture. Our friendly staff members go above and beyond to ensure that guests feel welcomed and cared for, creating a memorable dining atmosphere that keeps patrons coming back for more.",
    image: biryanibar,
  },
  {
    title: "London",
    content:
    "we provide warm hospitality that embodies the spirit of South Asian culture. Our friendly staff members go above and beyond to ensure that guests feel welcomed and cared for, creating a memorable dining atmosphere that keeps patrons coming back for more.",
    image: biryanibar,
  },
  // {
  //   title: "Paid Media",
  //   content:
  //     "We run targeted paid advertising campaigns across various platforms to amplify your reach and drive conversions.",
  //   image: structureImage5,
  // },
  // {
  //   title: "Digital Lead Generation",
  //   content:
  //     "Using the latest digital tools and techniques, we identify and engage with potential investors online, delivering qualified leads to your doorstep.",
  //   image: structureImage6,
  // },
];


  

  const Slideshow = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [expandedIndex, setExpandedIndex] = useState(-1); // Initialize with no expanded content
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex === slidesData.length - 1 ? 0 : prevIndex + 1));
      }, 5500);
  
      return () => clearInterval(interval);
    }, []);
  
    const handleDotClick = (index) => {
      setCurrentIndex(index);
    };
  
    const toggleContent = (index) => {
      setExpandedIndex((prevIndex) => (prevIndex === index ? -1 : index));
    };


   
  
    return (
      <div className="slideshow">
        <div className="activities">
      <h1>Our Franchise</h1>
                    </div>

        <div className="slideshowSlider" style={{ transform: `translate3d(${-currentIndex * 100}%, 0, 0)` }}>
          {slidesData.map((slide, index) => (
            <div key={index} className="slide" style={{ backgroundColor: slide.backgroundColor , padding:"0 20px "}}>
              <Row>
                
                <Col lg={6}>
                  <div className="img-section">
                    <div className="discover-img">
                      <img src={slide.image} alt="slideImage" />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="content-section">
                    <h3>{slide.title}</h3>
                    <div  className={`content-in ${expandedIndex === index ? 'expanded' : ''}`}>
                      {slide.content}
                    </div>
                    {/* <button className="btn btn-primary" onClick={() => toggleContent(index)}>
                      {expandedIndex === index ? "Read Less" : "Read More"}
                      <img src="/static/media/left-arrow.f92d8c5aca7e283c63a2d1691e1dfc8c.svg" alt="arrow" className="btn-arrow"></img>
                    </button> */}
                    
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </div>
  
        <div className="slideshowDots">
          {slidesData.map((_, index) => (
            <div
              key={index}
              className={`slideshowDot${currentIndex === index ? " active" : ""}`}
              onClick={() => handleDotClick(index)}
            ></div>
          ))}
        </div>
      </div>
    );
  };
  
  export default Slideshow;