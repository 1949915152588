import React from "react";
import { useEffect } from 'react';
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";


function SecondaryMenu(props) {

  useEffect(() => {
    if (props.linkName) {

      const linkToScroll = document.getElementById(props.linkName);
      const scrollAmount = linkToScroll.getBoundingClientRect().left;
      document.querySelector(".invisible-scrollbar").scrollLeft = scrollAmount - 125;
    }
  }, []);

  return (
    <>
      <div className="secondary-links sticky-bx">
        <Nav id="navbar" className="nav-about invisible-scrollbar">
          <ul id="navlinks" className="d-flex justify-content-between w-100 mb-0">
            <li>
              <NavLink
                to="/about"
                id="about"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                id="principles"
                to="/principles"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Our Vision
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                id="achievements"
                to="/achievements"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Achievements
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                to="/certifications"
                id="certificates"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Our Certifications
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/about-contact"
                id="about-contact"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Office Locations                 
                </NavLink>
            </li>
            <li>
              <NavLink
                id="our-team"
                to="/our-team"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Our Team
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/about-career"
                id="about-career"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Career
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                id="software-associates"
                to="/software-associates"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Software Associates
              </NavLink>
            </li> */}
          </ul>
        </Nav>
      </div>
    </>
  );
}

export default SecondaryMenu;
