import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import "react-html5video/dist/styles.css";
import SoftwarePartners from "../SubComponents/SoftwarePartners";
import ParallaxSmallBanner from "../SubComponents/ParallaxSmallBanner";
import Img1 from "./../Images/alain-pham-P_qvsF7Yodw-unsplash.jpg";
import SecondaryMenu from "./SecondaryMenu";
import AboutCareer from "../Pages/Careers";
import Career from './../Images/career.jpg';

import { useRef } from 'react'

import emailjs from 'emailjs-com';


function SoftwareAssociates(props) {


  const form = useRef()
  function sendEmail(e) {
    // alert('good morning')
    e.preventDefault();

    emailjs.sendForm('service_gv7h749', 'template_mwsiptw', form.current, 'K49l0okANjKtyoz_w')

      .then((result) => {
        console.log(result.text);
        if (result.text) {
          // window.location.reload()
          window.location = "/careers"

          alert('your information has submited')
          // window.location.reload()
        }
      }, (error) => {
        console.log(error.text);
        alert('your information not submited')
      });
  }

  return (
    <>
      <Header />

      <div className="scrll">
        <ParallaxSmallBanner img={Img1} title="Office Location" />
      </div>
      <div className="pos-rel">
        <div className="w-100 py-5  pad-60">
          <div className="pt-2 pb-5 text-center">
            <SecondaryMenu linkName={props.linkName} />
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="pt-5 px-4 w-100 partners">

                {/* contact */}
                 {/* Contact form starts */}

            <div className='pos-rel'>
                {/* <div class="w-100 form-section py-5 gr-bg">
                    <div class="px-4">
                        <div className="contact-area">
                            <div class="row contact-form contact-us">
                                <div class="col-lg-6">
                                    <div className="contact-heading abt-txt">
                                        <h1>Let's Talk</h1>

                                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo neque distinctio delectus nihil exercitationem nam labore voluptatum nobis similique.
                                        </p>

                                    </div>
                                    <form action="" ref={form} onSubmit={sendEmail}>
                                        <div className="row">

                                            <div className="col-lg-6 mb-4">




                                                <label>First Name</label>
                                                <input type="text" placeholder="Enter First name" name='toFirstName' />

                                            </div>
                                            <div className="col-lg-6 mb-4">


                                                <label>Last Name</label>
                                                <input type="text" placeholder="Enter Last name" name='toLastName' />


                                            </div>
                                            <div className="col-lg-6 mb-4">

                                                <label>Email</label>
                                                <input type="email" placeholder="Enter Email Address" name='toEmailId' />

                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <label>Subject</label>
                                                <input type="text" placeholder="Enter Subject" name='subject' />
                                            </div>
                                            <div className="mb-4">
                                                <label>Message</label>
                                                <textarea type="text" rows={4} placeholder="Enter Message" name='message' />
                                            </div>


                                            <div className="send-button">

                                                <button className="nav-btn contact-send-btn">Send</button>

                                            </div>


                                        </div>
                                        <div className="">


                                        </div>
                                    </form>
                                </div>
                                <div class="col-lg-6">

                                </div>
                            </div>

                        </div>
                    </div>
                </div > */}
                {/* contact form ends */}



                <div className="w-100 pt-0">
                    <div className="px-0 mb-0">

                        <div className="slider">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d42966910.30504671!2d-135.486940083993!3d48.906016237786034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b0d03d337cc6ad9%3A0x9968b72aa2438fa5!2sCanada!5e0!3m2!1sen!2sin!4v1708593013623!5m2!1sen!2sin" width="100%" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                           

                        </div>
                    </div>
                </div>
                {/* buildings starts */}

                {/* buildings ends */}




                {/* <Footer /> */}
            </div>

                {/* contact form ends */}



                <div className="contact-heading text-center max-600 mx-auto mb-4">
                  <p>
                    We’ve done it carefully and simply. Combined with the
                    ingredients make for beautiful landings. It is definitely
                    the tool you need to speed up your design process.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default SoftwareAssociates;
