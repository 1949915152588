import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import OilAndGasBanner from '../SubComponents/Banner'
import { Link } from 'react-router-dom'
import abt1 from './../Images/abt2.jpg';
import OilGas1 from './../Images/oil-gas1.png';
import OilGas2 from './../Images/oil-gas2.png';
import OilGas3 from './../Images/oil-gas3.png';
import Bld1 from './../Images/bld1.png';
import Bld2 from './../Images/bld2.png';
import Bld3 from './../Images/bld3.png';
import Bld4 from './../Images/bld4.png';
import Magazine from './../Images/Magzine.jpg';
import Certificate2 from './../Images/certificate2.jpeg';

import Image1 from './../Images/image-1.png';
import Alma from './../Images/alma.jpg';
// tabs

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Banner from '../SubComponents/Banner'

import contact from './../Images/bim-bg1.jpeg';
import TrustedSlider from '../SubComponents/TrustedSlider'
import Reviews from '../SubComponents/Reviews'
import Pannellum1 from '../SubComponents/Pannellum'
import SectionWipes from '../SubComponents/ParallaxHero'



function ProductDetails() {
    return (
        <>
            <Header />
            {/* <Banner img={OilGas2} title='Products' /> */}
            <SectionWipes img={contact} title='Product' />
            <div className='pos-rel'>
                <div className="w-100 pt-5 ">
                    <div className="contact-heading max-600 mx-auto text-center px-4">
                        <h1>Products</h1>

                        <p>We are a uniquely diverse range of consultants delivering extremely high profile projects across the world, working to meet some of the world’s greatest challenges.
                        </p>

                    </div>
                </div>
                <Pannellum1 />
                {/* certifications starts */}
                <div className="w-100 ">
                    <div className="">

                        
                    </div>
                </div >
                {/* in services starts */}
                <div className="w-100 pt-5">
                    <div className="px-4">

                        <div className="row w-100 mx-0">
                            <div className="col-lg-6 my-auto order-lg-1 order-2">
                                <div className="contact-heading abt-txt">

                                    <h1>SkyCiv Beam</h1>

                                    <p>Covering momentous projects under our guidance we are sufficient to deliver complete functional solutions in the field of oil and gas plants. Our workforce consists of selective skilled engineers and percipient management personnel having adequate domain knowledge in this industry and major leading softwares.The movement of people ensures that our cities thrive and grow. But as the world’s population increases, and globalisation and urbanisation continue t
                                    </p>
                                    <div className="">
                                        <Link className="nav-btn me-4" to="/">Get A Quote</Link>
                                       
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-6 order-lg-2 order-1">
                                <div className="side-img">
                                    <img src={Image1} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* in servcices ends */}



                <div className="w-100 pt-5">
                    <div className="px-4">
                        <div className="contact-heading mx-auto text-center px-4">
                            <h1>Discover key features of SkyCiv Beam</h1>

                            <p>We are a uniquely diverse range of consultants delivering extremely high profile.
                            </p>

                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col-lg-6">
                                <div className="side-img">
                                    <img src={Image1} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 my-auto">
                                <div className="contact-heading abt-txt prod-side mt-4">

                                    <h1><span>Give Your Site A New Look</span></h1>

                                    <p>Service range including technical skills, design, business understanding.SkyCiv Beam Software has a feature never seen before in other products - it shows the full working out of its solution.</p>
                                    <div className="list-products">
                                        <ul>
                                            <li>Range including technical skills</li>
                                            <li>The shear force diagrams by method of cuts/sections.</li>
                                            <li>The bending moment diagrams by method of cuts/sections.</li>
                                            <li>The bending moment diagrams by method of cuts/sections.</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


                {/* certifications ends */}
                <div className="w-100 ">
                    <div className="px-4">

                        <div className="row mx-0 w-100">

                            <div className="col-lg-6 order-lg-1 order-2 my-auto">
                                <div className="contact-heading abt-txt prod-side mt-4">

                                    <h1><span>Give Your Site A New Look</span></h1>

                                    <p>Service range including technical skills, design, business understanding.SkyCiv Beam Software has a feature never seen before in other products - it shows the full working out of its solution.</p>
                                    <div className="list-products">
                                        <ul>
                                            <li>Range including technical skills</li>
                                            <li>The shear force diagrams by method of cuts/sections.</li>
                                            <li>The bending moment diagrams by method of cuts/sections.</li>
                                            <li>The bending moment diagrams by method of cuts/sections.</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-6 order-lg-2 order-1">
                                <div className="side-img">
                                    <img src={Image1} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-100">
                    <div className="px-4">

                        <div className="row mx-0 w-100">
                            <div className="col-lg-6">
                                <div className="side-img">
                                    <img src={Image1} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 my-auto">
                                <div className="contact-heading abt-txt prod-side mt-4">

                                    <h1><span>Give Your Site A New Look</span></h1>

                                    <p>Service range including technical skills, design, business understanding.SkyCiv Beam Software has a feature never seen before in other products - it shows the full working out of its solution.</p>
                                    <div className="list-products">
                                        <ul>
                                            <li>Range including technical skills</li>
                                            <li>The shear force diagrams by method of cuts/sections.</li>
                                            <li>The bending moment diagrams by method of cuts/sections.</li>
                                            <li>The bending moment diagrams by method of cuts/sections.</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="mx-auto mb-3 text-center custom-flex">
                            <Link className="nav-btn me-4 custom-btn" to="/">Get A Quote</Link>
                            <Link className="nav-btn btn-w-1 custom-btn" to="/">Download Brochure</Link>
                        </div>
                    </div>
                </div>


                <div className="w-100 pt-5">
                    <div className="px-4">
                        <div className="contact-heading abt-txt mx-auto text-center px-4">
                            <h1>Trusted by engineers around the world</h1>

                            <p className="text-center">We are a uniquely diverse range of consultants delivering extremely high profile.</p>

                        </div>
                        <div className="slider">
                            <TrustedSlider />

                        </div>
                    </div>
                </div>

                <div className="w-100 pt-5">
                    <div className="px-4 mb-5">

                        <div className="slider">
                            <Reviews />

                        </div>
                    </div>
                </div>
                {/* buildings starts */}
                
                {/* buildings ends */}




                <Footer />
            </div>
        </>
    )
}

export default ProductDetails