import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import "react-html5video/dist/styles.css";
import SecondaryMenu from "./SecondaryMenu";
import ParallaxSmallBanner from "../SubComponents/ParallaxSmallBanner";
import Img1 from "./../Images/alain-pham-P_qvsF7Yodw-unsplash.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tab from "react-bootstrap/Tab";
import coreimg2 from "./../Images/core principles/a011dc2a-e224-4ff0-975c-b49f3e2f632f.jpg";
import Purpose from "../Images/purpose-1.jpg";
import Goals from "../Images/goals.jpg";
import Mission from "../Images/mission.jpg";
import Vision from "../Images/vision.jpg";
import Values from "../Images/values.jpg";
const Principles = (props) => {
  const [toggleValues, setToggleValues] = useState(false);
  const [toggleGoals, setToggleGoals] = useState(false);
  const [toggleMission, setToggleMission] = useState(false);
  const [toggleVision, setToggleVision] = useState(false);
  const [togglePurpose, setTogglePurpose] = useState(false);
  function handleTogglePurpose() {
    setTogglePurpose((prev) => !prev);
  }
  function handleToggleValues() {
    setToggleValues((prev) => !prev);
  }
  function handleToggleGoals() {
    setToggleGoals((prev) => !prev);
  }
  function handleToggleMission() {
    setToggleMission((prev) => !prev);
  }
  function handleToggleVision() {
    setToggleVision((prev) => !prev);
  }
  return (
    <>
      <Header />
      <div className="scrll">
        <ParallaxSmallBanner img={Img1} title="Our vision" />
      </div>

      <div className="pos-rel">
        <div className="pad-60 py-5">
          <div className="pt-2 pb-5 text-center">
            <SecondaryMenu linkName={props.linkName} />
          </div>
          <div class="container-fluid">

            <section className="principles-layout">
              <div className="d-flex">
                <img src={Vision} alt="Purpose" />
                <div class="principle-content left">
                  <div className="d-flex flex-nowrap justify-content-start">
                    <div className="w-5p">&nbsp;</div>
                    <h1 className="manager title">Crafting Culinary Excellence</h1>
                  </div>
                  <p>
                  At Bedi Group, we believe that food transcends mere sustenance—it’s an art form. Our vision is to elevate your culinary experience by curating exceptional offerings that blend tradition with innovation. From our flagship venture, Biryani Bar, to future endeavors, we’re committed to delighting your taste buds and creating memorable dining moments.
                  </p>
                </div>
              </div>

              <div className="d-flex">
                <div class="principle-content right">
                  <div className="d-flex flex-nowrap justify-content-start">
                    <div className="w-5p">&nbsp;</div>
                    <h1 className="manager title">Global Expansion, Local Flavor</h1>
                  </div>
                  <p>
                  Bedi Group’s journey extends beyond borders. As we strategically franchise Biryani Bar across North America, we bring the rich tapestry of Indian flavors to diverse communities. Our vision is to celebrate cultural diversity through food, infusing each dish with authenticity and love. Join us on this flavorful voyage!
                  </p>
                </div>
                <img src={Mission} alt="Mission" />
              </div>
              <div className="d-flex">
                <img src={Purpose} alt="Vision" />

                <div class="principle-content left">
                  <div className="d-flex flex-nowrap justify-content-start">
                    <div className="w-5p">&nbsp;</div>
                    <h1 className="manager title">Pioneering Food Manufacturing</h1>
                  </div>
                  <p>
                  . Bedi Group’s vision is to set new industry standards, ensuring that every product leaving our facility embodies quality, safety, and culinary excellence.
                  </p>
                </div>
              </div>
              {/* <div className="d-flex">
                <div class="principle-content right">
                  <div className="d-flex flex-nowrap justify-content-start">
                    <div className="w-5p">&nbsp;</div>
                    <h1 className="manager title">A Legacy of Real Results</h1>
                  </div>
                  <p>
                  Our vision isn't confined to words; it's a commitment to deliver real results. We measure our success not by the grandeur of our aspirations but by the tangible improvements we bring to the lives and livelihoods of those we serve. Our vision is grounded in reality, and our dedication to making it a reality drives our every action.
                  </p>
                </div>
                <img src={Values} alt="Values" />
              </div>
              <div className="d-flex">
                <img src={Goals} alt="Goals" />
                <div class="principle-content left">
                  <div className="d-flex flex-nowrap justify-content-start">
                    <div className="w-5p">&nbsp;</div>
                    <h1 className="manager title">Empowering a Better Tomorrow</h1>
                  </div>
                  <p>
                  In essence, our vision is to empower a better tomorrow—a tomorrow defined by economic vitality, diplomatic harmony, and unprecedented success for our clients and partners. It's a future where InterDiplom Masters is synonymous with real-world impact, innovation, and progress.
                  </p>
                </div>
              </div> */}
            </section>
          </div>

        </div>
        <Footer />
      </div>
    </>
  );
};

export default Principles;
