import React, { Fragment, useState } from "react";
import "./index.css";
import Header from "../../Components/Header";
import Banner from "../../SubComponents/Banner";
import PebService from "../../Images/peb-background.jpg";
import { Col, Form, Row } from "react-bootstrap";
import PubServiceContent from "../../Images/peb-service.jpg";
import { Link } from "react-router-dom";
import { structuredData } from "./data";
import rightArrow from "../../Images/icons/right-arrow.svg";
import leftArrow from "../../Images/icons/left-arrow.svg";
import CustomCarousal from "./CustomCarousel";
import PEBImg1 from "../../Images/peb/peb-1.png";
import PEBImg2 from "../../Images/peb/peb-2.png";
import PEBImg3 from "../../Images/peb/peb-3.png";
import PEBImg4 from "../../Images/peb/peb-4.png";
import SectionWipes from '../../SubComponents/ParallaxHero'
import Footer from "../../Components/Footer";
import Oilgas22 from '../../Images/2product.png';


const PebStructure = () => {
  const [stucturedSlide, setStucturedSlide] = useState(0);
  const [active, setActive] = useState("right");

  const handleStucturedSlide = (index) => {
    setStucturedSlide(index);
  };
  const handleSlideByArrow = (value) => {
    setActive(value);
    if (value === "left" && stucturedSlide > 0) {
      setStucturedSlide(stucturedSlide - 1);
    } else {
      console.log(
        "enter",
        !structuredData?.length > stucturedSlide,
        structuredData?.length,
        stucturedSlide
      );
      if (structuredData?.length - 1 > stucturedSlide) {
        setStucturedSlide(stucturedSlide + 1);
      }
    }
  };
  const sectionsData = [
    {
      content1: {
        strong: "Design and Engineering:",
        paragraph:
          "Pre-engineered buildings are designed and engineered off-site using STAADPro software, ensuring precise and efficient manufacturing",
      },
      content2: {
        imgSrc: PEBImg1,
        imgAlt: "PEB Image 1",
      },
      content3: {
        strong: "Design and Engineering:",
        paragraph:
          "PEB's are manufactured in a controlled factory environment, with building components pre-cut, pre-drilled, and ready for assembly on-site. This leads to faster construction timelines and reduced labor requirements.",
      },
    },
    {
      content1: {
        strong: "Construction Process:",
        paragraph:
          "PEB's are manufactured in a controlled factory environment, with building components pre- cut, pre-drilled, and ready for assembly on-site. This leads to faster construction timelines and reduced labor requirements.",
      },
      content2: {
        imgSrc: PEBImg2,
        imgAlt: "PEB Image 2",
      },
      content3: {
        strong: "Construction Process:",
        paragraph:
          "Hot rolled buildings involve the fabrication and assembly of structural steel components on-site, which can be more time-consuming and labor-intensive.",
      },
    },
    {
      content1: {
        strong: "Cost and Efficiency:",
        paragraph:
          "The streamlined manufacturing process, reduced construction time, and minimized material waste contribute to cost savings. Member sizes are tailored after technically analyzing the member, further reducing construction costs.",
      },
      content2: {
        imgSrc: PEBImg3,
        imgAlt: "PEB Image 3",
      },
      content3: {
        strong: "Cost and Efficiency:",
        paragraph:
          "Hot rolled buildings, on the other hand, may have higher material and labor costs due to not being able to pr and longer construction timelines",
      },
    },
    {
      content1: {
        strong: "Flexibility and Customization:",
        paragraph:
          "The streamlined manufacturing process, reduced construction time, and minimized material waste contribute to cost savings. Member sizes are tailored after technically analyzing the member, further reducing construction costs.",
      },
      content2: {
        imgSrc: PEBImg4,
        imgAlt: "PEB Image 4",
      },
      content3: {
        strong: "Flexibility and Customization:",
        paragraph:
          "Hot rolled buildings, while customizable to some extent, may require more extensive modifications and additional engineering work to accommodate changes after the initial construction",
      },
    },
    // Add more sections data as needed
  ];

  return (
    <Fragment>
      <Header />
      <SectionWipes img={Oilgas22} title="Restaurant" />
      <div className='pos-rel'>

        <div className="container-section">
          <div className="overview-title">
            <h3>OVERVIEW</h3>
            <h1 className="heading-text">
              On <span> Biryani Bar Restaurant</span>
            </h1>
          </div>
          <p className="mb-4">“Bedi Group” proudly presents our chain of Indian-style restaurants, offering a unique dining experience that brings the rich flavors and diverse culinary traditions of India to your table.</p>
          <p className="mb-4">Our restaurants are spread across various locations, each providing an authentic Indian dining experience. We specialize in a variety of Indian cuisines, from the aromatic biryanis of the north to the spicy curries of the south.</p>
          <p className="mb-4">Franchising with us at “Biryani Bar” is a promising opportunity. As a franchisee, you will be part of a proven business model with a well-known brand. We provide comprehensive support to our franchisees, handling everything from site selection and restaurant setup to training and marketing. This ensures a smooth start and ongoing success for your business.</p>
          <p className="mb-4">“Biryani Bar” is more than just a restaurant; it’s a culinary journey that celebrates the diverse flavors of India. By franchising with us, you’re not just investing in a business, but also becoming part of a community that celebrates food and culture. We look forward to welcoming you to the “Biryani Bar” family. </p>
        </div>
          <div className="custom-flex" style={{textAlign:"center"}}>
            {/* <Link className="nav-btn me-4 custom-btn quote" to="/">
              Get A Quote
            </Link> */}
            <Link className="nav-btn btn-w-1 custom-btn download" to="/">
              Download Menu
            </Link>
          </div>
{/* 
        <div className="img-cont" style={{padding:"50px 200px"}}>
          <img
            src={PubServiceContent}
            className="w-100" alt="PubServiceContent" />
        </div> */}

        {/* <div className="work-section">
          <div className="work-title">
            <h3>HOW IT WORKS</h3>
            <h1>
              At IntelBuild we have <span> structured </span>the service process
              which ensure clients....
            </h1>
          </div>
          <Row className="mt-4">
            <Col md={4} lg={4}>
              <div className="check-list-main">
                <div className="check-list desktop">
                  {structuredData?.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex align-items-center check-item"
                      onClick={() => handleStucturedSlide(index)}
                    >
                      <Form.Check
                        type="radio"
                        aria-label="radio 1"
                        name="check"
                        checked={index === stucturedSlide}
                      />
                      <h3>{item?.title}</h3>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col md={8} lg={8}>
              <div className="check-list-content">
                {structuredData?.map(
                  (item, index) =>
                    index === stucturedSlide && (
                      <div key={index}>
                        <h3 className="mobile">{item?.title}</h3>
                        <p className="paragraph-content">{item?.content}</p>
                        <div className="image-container">
                          <img className="images"
                            src={item?.image}
                            alt={item?.image}
                          />
                        </div>
                      </div>
                    )
                )}
                <div className="arrow-box">
                  <button
                    type="button"
                    className={`arrow-button ${active === "left" && "arrow-active"
                      }`}
                    onClick={() => handleSlideByArrow("left")}
                  >
                    {active !== "left" ? (
                      <img className="direct" src={leftArrow} alt="leftArrow" />
                    ) : (
                      <img
                        src={rightArrow}
                        alt="rightArrow"
                        className="direct direction"
                      />
                    )}
                  </button>
                  <button
                    type="button"
                    className={`arrow-button ${active === "right" && "arrow-active"
                      }`}
                    onClick={() => handleSlideByArrow("right")}
                  >
                    {active !== "left" ? (
                      <img src={rightArrow} alt="rightArrow" />
                    ) : (
                      <img
                        src={leftArrow}
                        alt="leftArrow"
                        className="direction"
                      />
                    )}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div> */}
                     {/* /////////////  */}
                     
                     {/* /////////////  */}

        <CustomCarousal />
        {/* <div>
          <section className="peb--wrapper">
            <div className="d-md-flex content-row">
              <div className="firstHeading">
                <h1 className="main-heading">
                  PRE-ENGINEERED BUILDINGS
                </h1>
              </div>
              <div className="secondHeading"></div>
              <div className="thirdHeading">
                <h1 className="main-heading">
                  HOT ROLLED BUILDINGS
                </h1>
              </div>
            </div>
            {sectionsData.map((section, index) => (
              <div className="d-flex content-row">
                <div className="first">
                  <strong style={{ fontSize: "20px" }}>
                    {section.content1.strong}
                  </strong>
                  <p>{section.content1.paragraph}</p>
                </div>
                <div className="second">
                  <img
                    src={section.content2.imgSrc}
                    alt={section.content2.imgAlt}
                    style={{ width: "4rem", height: "auto", alignSelf: "center" }}
                  />
                </div>
                <div className="third">
                  <strong style={{ fontSize: "20px" }}>
                    {section.content3.strong}
                  </strong>
                  <p>{section.content3.paragraph}</p>
                </div>
              </div>
            ))}
            <div className="mobile">
              <div className="d-md-flex">
                <div className="firstHeading">
                  <h1 className="main-heading">
                    HOT ROLLED BUILDINGS
                  </h1>
                </div>
              </div>
            </div>
            {sectionsData.map((section, index) => (
              <div className="mobile">
                <div className="d-flex content-row">
                  <div className="first">
                    <strong style={{ fontSize: "20px" }}>
                      {section.content3.strong}
                    </strong>
                    <p>{section.content3.paragraph}</p>
                  </div>
                  <div className="second">
                    <img
                      src={section.content2.imgSrc}
                      alt={section.content2.imgAlt}
                      style={{ width: "4rem", height: "auto", alignSelf: "center" }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </section>
        </div> */}
      </div>
            <Footer />
    </Fragment>
  );
};

export default PebStructure;
