import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import "react-html5video/dist/styles.css";
import SoftwarePartners from "../SubComponents/SoftwarePartners";
import ParallaxSmallBanner from "../SubComponents/ParallaxSmallBanner";
import Img1 from "./../Images/alain-pham-P_qvsF7Yodw-unsplash.jpg";
import SecondaryMenu from "./SecondaryMenu";
import AboutCareer from "../Pages/Careers";
import Career from './../Images/career.jpg';

import { useRef } from 'react'

import emailjs from 'emailjs-com';


function SoftwareAssociates(props) {


  const form = useRef()
    function sendEmail(e) {
        // alert('good morning')
        e.preventDefault();

        emailjs.sendForm('service_gv7h749', 'template_mwsiptw', form.current, 'K49l0okANjKtyoz_w')

            .then((result) => {
                console.log(result.text);
                if (result.text) {
                    // window.location.reload()
                    window.location = "/careers"

                    alert('your information has submited')
                    // window.location.reload()
                }
            }, (error) => {
                console.log(error.text);
                alert('your information not submited')
            });
    }

  return (
    <>
      <Header />

      <div className="scrll">
        <ParallaxSmallBanner img={Img1} title="Career" />
      </div>
      <div className="pos-rel">
        <div className="w-100 py-5  pad-60">
          <div className="pt-2 pb-5 text-center">
            <SecondaryMenu linkName={props.linkName} />
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="pt-5 px-4 w-100 partners">



              <div className='pos-rel'>
                <div class="w-100 form-section gr-bg">
                    <div class="">
                        <div className="contact-area">
                            <div class="row contact-form w-100 mx-0">
                                <div class="col-lg-6 py-5">
                                    <div class="px-4">
                                        <div className="contact-heading abt-txt">
                                            <h1>Careers With intelBUILD</h1>

                                            <p>We are constantly looking out for talented and professional detailers, checkers, and engineers. If you a professional who can make a difference by constantly contributing to the organization and an evolving personality who aims at higher growth aspiration with a zeal to prove yourself, Please feel free to reach out to us.</p>

                                        </div>

                                        <form action="" ref={form} onSubmit={sendEmail}>
                                            <div className="row">

                                                <div className="col-lg-6 mb-4">




                                                    <label>First Name</label>
                                                    <input type="text" placeholder="Enter First name" name='toFirstName' />

                                                </div>
                                                <div className="col-lg-6 mb-4">


                                                    <label>Last Name</label>
                                                    <input type="text" placeholder="Enter Last name" name='toLastName' />


                                                </div>
                                                <div className="col-lg-6 mb-4">

                                                    <label>Email</label>
                                                    <input type="email" placeholder="Enter Email Address" name='toEmailId' />

                                                </div>
                                                <div className="col-lg-6 mb-4">
                                                    <label>Subject</label>
                                                    <input type="text" placeholder="Enter Subject" name='subject' />
                                                </div>
                                                <div className="mb-4">
                                                    <label>Message</label>
                                                    <textarea type="text" rows={4} placeholder="Enter Message" name='message' />
                                                </div>


                                                <div className="send-button">

                                                    <button className="nav-btn">Send</button>

                                                </div>


                                            </div>
                                            <div className="">


                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="col-lg-6 px-0">
                                    <div class="careers-img">
                                        <img src={Career} alt="" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div >
                </div >
                {/* contact form ends */}
                
                
                
                <div className="contact-heading text-center max-600 mx-auto mb-4">
                  <p>
                    We’ve done it carefully and simply. Combined with the
                    ingredients make for beautiful landings. It is definitely
                    the tool you need to speed up your design process.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default SoftwareAssociates;
