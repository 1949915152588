import React, { useMemo } from "react";
import "./../SubComponents/vid.css";
import video from "./../Images/intro-videos/interdiplom video-intro.mp4";
import { DefaultPlayer as Video } from "react-html5video";

function VideoPlayer() {
  return useMemo(() => (
    <div className="slideshow">
      <div className="slideshowSlider">
        <Video className="slide" autoPlay loop muted controls >
          <source src={video} type="video/mp4" />
        </Video>

      </div>


    </div>
  ), []);

}

export default VideoPlayer;
