import React, { useContext, useState } from "react";
import ProductsData from "./../Data/ProductsData"; // Real Estate
import ProductsData1 from "../Data/ProductsData6"; //Restaurant
import ProductsData2 from "./../Data/ProductsData2"; // Frozen Food
import ProductsData3 from "./../Data/ProductsData3"; // Pharma
import ProductsData4 from "./../Data/ProductsData4"; // Franchise
import ProductsData5 from "./../Data/ProductsData5"; // Coming soon
import { v4 as uuidv4 } from "uuid"
import ProjectPopup from "./ProjectPopup";
import { Swiper, SwiperSlide } from "swiper/react";
import "./ProductsFilter.css"

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
// import required modules
import { Grid, Navigation } from "swiper/modules";

// import css
import './ProductsFilter.css'
import { useNavigate } from "react-router-dom";
import DataContext from "../Context/bediContext";

const ProductsFilter = () => {
  const [buttonPopup, setButtonPopup] = useState(false);
  const [modal, setModal] = useState(false);
  const [tempdata, setTempdata] = useState([]);
  const [items, setItems] = useState(ProductsData);
  const [items1, setItems1] = useState(ProductsData1);
  const [items2, setItems2] = useState(ProductsData2);
  const [items3, setItems3] = useState(ProductsData3);
  const [items4, setItems4] = useState(ProductsData4);
  const [items5, setItems5] = useState(ProductsData5);
  const [activeButton, setActiveButton] = useState("All");
  const [index, setIndex] = useState(0);

  const navigate = useNavigate()

  const getData = (image, service, category) => {
    let tempData = [image, service, category];
    setTempdata((item) => [image]);
    return setButtonPopup(true);
  };
  const getData2 = (image, service, category) => {
    let tempData = [image, service, category];
    setTempdata((item) => [image]);
    return setButtonPopup(true);
  };
  const settings = {
    slidesToShow: 6,
    speed: 500,
    rows: 2,
    slidesPerRow: 2
  };
  // const handleShow = () => setShow(true);

  const filterItem = (catItem) => {
    setActiveButton(catItem);
    const updatedItems = ProductsData.filter((curElem) => {
      return curElem.category === catItem;
    });
    setItems(updatedItems);
  };

  // const filterItem2 = (catItem) => {
  //   setActiveButton(catItem);
  //   const updatedItems = ProductsData2.filter((curElem) => {
  //     return curElem.category === catItem;
  //   });
  //   setItems2(updatedItems);
  // };

  // const filterItem3 = (catItem) => {
  //   setActiveButton(catItem);
  //   const updatedItems = ProductsData3.filter((curElem) => {
  //     return curElem.category === catItem;
  //   });
  //   setItems3(updatedItems);
  // };

  // const filterItem4 = (catItem) => {
  //   setActiveButton(catItem);
  //   const updatedItems = ProductsData4.filter((curElem) => {
  //     return curElem.category === catItem;
  //   });
  //   setItems4(updatedItems);
  // };

  // const filterItem5 = (catItem) => {
  //   setActiveButton(catItem);
  //   const updatedItems = ProductsData5.filter((curElem) => {
  //     return curElem.category === catItem;
  //   });
  //   setItems5(updatedItems);
  // };
const {getId} = useContext(DataContext)

  const handleRedirect = () => {
    navigate("/projectview")
   
  } 

  const handleEstateRedirect = (takeId) => {
    navigate("/projectview1")
    getId(takeId)
  }


  const handleRestaurantsRedirect = (takeId) => {
    navigate("/projectview2")
    getId(takeId)

  }


  const handleFoodRedirect = (takeId) => {
    navigate("/projectview3")
    getId(takeId)

  }

  const handlePharmaRedirect = (takeId) => {
    navigate("/projectview4")
    getId(takeId)

  }
  
  const handleFranchiseRedirect = (takeId) =>{
    navigate("/projectview5")
    getId(takeId)

  }

  return (
    <>
      <div className="w-100 pt-5">
        <div className="contact-heading max-600 mx-auto text-center px-4">
          <h1>Our Portfolio</h1>
          <p>
          Our company takes pride in offering a uniquely diverse range of investment
           portfolios that have consistently delivered healthy returns across the globe.
            With a track record of delivering robust returns, our investment portfolios stand as a testament
             to our commitment to excellence and our ongoing pursuit of creating value for our investors.
          </p>
        </div>
        <div className="products-tabs">
          <div className="products-tab mb-4 d-flex justify-content-between">
            {/* <button
              className={
                activeButton === "All" ? "filter-btn active" : "filter-btn"
              }
              onClick={() => filterItem("All")}
            >
              All
            </button> */}
            <button
              className={
                activeButton === "Real Estate"
                  ? "filter-btn active"
                  : "filter-btn"
              }
              onClick={() => filterItem("Real Estate")}
            >
              Real Estate
            </button>
            <button
              className={
                activeButton === "Restaurants"
                  ? "filter-btn active"
                  : "filter-btn"
              }
              onClick={() => filterItem("Restaurants")}
            >
              Restaurants
            </button>
            <button
              className={
                activeButton === "Frozen Food"
                  ? "filter-btn active"
                  : "filter-btn"
              }
              onClick={() => filterItem("Frozen Food")}
            >
              Frozen Food
            </button>
            <button
              className={
                activeButton === "Pharma"
                  ? "filter-btn active"
                  : "filter-btn"
              }
              onClick={() => filterItem("Pharma")}
            >
              Pharma
            </button>
            <button
              className={
                activeButton === "Franchise"
                  ? "filter-btn active"
                  : "filter-btn"
              }
              onClick={() => filterItem("Franchise")}
            >
              Franchise
            </button>
            <button
              className={
                activeButton === "Coming soon"
                  ? "filter-btn active"
                  : "filter-btn"
              }
              onClick={() => filterItem("Coming soon")}
            >
              Coming soon
            </button>
            {/* <button
              className={
                activeButton === "bim" ? "filter-btn active" : "filter-btn"
              }
              onClick={() => filterItem("bim")}
            >
              3D Automation Engineering
            </button>
            <button
              className={
                activeButton === "sus" ? "filter-btn active" : "filter-btn"
              }
              onClick={() => filterItem("sus")}
            >
              Sustainable Design &amp; Engineering
            </button>
            <button
              className={
                activeButton === "tructural-audit"
                  ? "filter-btn active"
                  : "filter-btn"
              }
              onClick={() => filterItem("tructural-audit")}
            >
              Structural Audit
            </button> */}
          </div>
        </div>
        {/* <div className="all-products px-2">
          <div className="row-1"> */}
         <div className="container-fluid">
          <h2 style={{ color: "rgba(29, 29, 29, 0.6)" }} className="ps-5">Real Estate</h2>
        </div>
        <Swiper
          modules={[Grid, Navigation]}
          spaceBetween={20}
          slidesPerView={3}
          grid={{
            fill: "row",
            rows: 1,
          }}
          breakpoints={{
            360: {
              rows: 1,
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              rows: 1,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1450: {
              rows: 2,
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          loop={true}
          navigation={true}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          className="mySwiper"
        >
          
          {items.map((item, index) => {
            return (
              
              <SwiperSlide>
                <div className="filter-bx" onClick={()=>handleEstateRedirect(item.Data)}>
                  <div className="filter-img">
                    <img src={item.image} alt="" />
                    <div className="filter-txt">
                      <span>{item.span}</span>
                    </div>
                  </div>

                  {/* <h2 className="project-location">Tamil Nadu</h2> */}

                  <h3 className="project-title" onClick={() => getData2(item.image, item.image)}>
                    {item.service}
                  </h3>

                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <hr />
        <div className="container-fluid">
          <h2 style={{ color: "rgba(29, 29, 29, 0.6)" }} className="ps-5">Restaurants</h2>
        </div>
        <Swiper
          modules={[Grid, Navigation]}
          spaceBetween={20}
          slidesPerView={3}
          grid={{
            fill: "row",
            rows: 1,
          }}
          breakpoints={{
            360: {
              rows: 1,
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              rows: 1,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1450: {
              rows: 2,
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          loop={true}
          navigation={true}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          className="mySwiper"
        >
          {items1.map((item, index) => {
            return (
              <SwiperSlide>
                <div className="filter-bx" onClick={()=>handleRestaurantsRedirect(item.Data)}>
                  <div className="filter-img">
                    <img src={item.image} alt="" />
                    <div className="filter-txt">
                    <span>{item.span}</span>
                    </div>
                  </div>

                  {/* <h2 className="project-location">Tamil Nadu</h2> */}
                  <h3 className="project-title" onClick={() => getData(item.image, item.image)}>
                    {item.service}
                  </h3>

                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <hr />
        <div className="container-fluid">
          <h2 style={{ color: "rgba(29, 29, 29, 0.6)" }} className="ps-5">Frozen Food</h2>
        </div>
        <Swiper
          modules={[Grid, Navigation]}
          spaceBetween={20}
          slidesPerView={3}
          grid={{
            fill: "row",
            rows: 1,
          }}
          breakpoints={{
            360: {
              rows: 1,
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              rows: 1,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1450: {
              rows: 2,
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          loop={true}
          navigation={true}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          className="mySwiper"
        >
          {items2.map((item, index) => {
            return (
              <SwiperSlide>
                <div className="filter-bx" onClick={()=>handleFoodRedirect(item.Data)}>
                  <div className="filter-img">
                    <img src={item.image} alt="" />
                    <div className="filter-txt">
                    <span>{item.span}</span>
                    </div>
                  </div>

                {/*  <h2 className="project-location">Tamil Nadu</h2> */}

                  <h3 className="project-title" onClick={() => getData2(item.image, item.image)}>
                    {item.service}
                  </h3>

                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <hr />
        <div className="container-fluid">
          <h2 style={{ color: "rgba(29, 29, 29, 0.6)" }} className="ps-5">Pharma</h2>
        </div>
        <Swiper
          modules={[Grid, Navigation]}
          spaceBetween={20}
          slidesPerView={3}
          grid={{
            fill: "row",
            rows: 1,
          }}
          breakpoints={{
            360: {
              rows: 1,
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              rows: 1,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1450: {
              rows: 2,
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          loop={true}
          navigation={true}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          className="mySwiper"
        >
          {items3.map((item, index) => {
            return (
              <SwiperSlide>
                <div className="filter-bx" onClick={()=>handlePharmaRedirect(item.Data)}>
                  <div className="filter-img">
                    <img src={item.image} alt="" />
                    <div className="filter-txt">
                    <span>{item.span}</span>
                    </div>
                  </div>

                  {/* <h2 className="project-location">Tamil Nadu</h2> */}

                  <h3 className="project-title" onClick={() => getData(item.image, item.image)}>
                    {item.service}
                  </h3>

                </div>
              </SwiperSlide>


            );
          })}
        </Swiper>

        <hr />
        <div className="container-fluid">
          <h2 style={{ color: "rgba(29, 29, 29, 0.6)" }} className="ps-5">Franchise</h2>
        </div>
        <Swiper
          modules={[Grid, Navigation]}
          spaceBetween={20}
          slidesPerView={3}
          grid={{
            fill: "row",
            rows: 1,
          }}
          breakpoints={{
            360: {
              rows: 1,
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              rows: 1,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1450: {
              rows: 2,
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          loop={true}
          navigation={true}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          className="mySwiper"
        >
          {items4.map((item, index) => {
            return (
              <SwiperSlide>
                <div className="filter-bx" onClick={()=>handleFranchiseRedirect(item.Data)}>
                  <div className="filter-img">
                    <img src={item.image} alt="" />
                    <div className="filter-txt">
                    <span>{item.span}</span>
                    </div>
                  </div>

                  {/* <h2 className="project-location">Tamil Nadu</h2> */}

                  <h3 className="project-title" onClick={() => getData(item.image, item.image)}>
                    {item.service}
                  </h3>

                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <hr />
        <div className="container-fluid">
          <h2 style={{ color: "rgba(29, 29, 29, 0.6)" }} className="ps-5">Coming soon</h2>
        </div>
        <Swiper
          modules={[Grid, Navigation]}
          spaceBetween={20}
          slidesPerView={3}
          grid={{
            fill: "row",
            rows: 1,
          }}
          breakpoints={{
            360: {
              rows: 1,
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              rows: 1,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1450: {
              rows: 2,
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          loop={true}
          navigation={true}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          className="mySwiper"
        >
          {items5.map((item, index) => {
            return (
              <SwiperSlide>
                <div className="filter-bx" >
                  <div className="filter-img">
                    <img src={item.image} alt="" />
                    <div className="filter-txt">
                    <span>{item.span}</span>
                    </div>
                  </div>

                  {/* <h2 className="project-location">Tamil Nadu</h2> */}

                  <h3 className="project-title" onClick={() => getData(item.image, item.image)}>
                    {item.service}
                  </h3>

                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <br></br>
        {/* <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <Masonry>
                {items.map((item, index) => {
                  return (
                    <>
                      <div className="item" key={index}>
                        <div className="filter-bx">
                          <div className="filter-img">
                            <img src={item.image} alt="" />
                            <div className="filter-txt">
                            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                          </div>
                          </div>
                            <h3 className="project-title" onClick={() => getData(item.image, item.image)}>
                              {item.service}
                            </h3>
                          
                        </div>
                      </div>
                    </>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry> */}
        {/* </div> */}
        {/* </div> */}
        <ProjectPopup
          image={tempdata[0]}
          trigger={buttonPopup}
          setTrigger={setButtonPopup}
        ></ProjectPopup>
      </div>
    </>
  );
};

export default ProductsFilter;
