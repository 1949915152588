import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { Link } from "react-router-dom";
import "react-select-search/style.css";
import Icon1 from "../Images/icons/product/market-svgrepo-com.svg";
import Icon2 from "../Images/icons/product/global-markets-svgrepo-com.svg";
import Icon3 from "../Images/icons/product/school-svgrepo-com(1).svg";
import Icon4 from "../Images/icons/product/crm-crm-svgrepo-com.svg";
import ProductIcon from "../Images/3productIcon.png";
import ProductIcon1 from "../Images/4productIcon.png"; // red sauce
import ProductIcon2 from "../Images/5productIcon.png";
import ProductIcon3 from "../Images/1sauce.png"; //white sauce
import ProductIcon4 from "../Images/2sauce.png";



function Products() {
  const options = [
    { name: "Swedish", value: "sv" },
    { name: "English", value: "en" },
    {
      type: "group",
      name: "Group name",
      items: [{ name: "Spanish", value: "es" }],
    },
  ];

  return (
    <>
      <Header />
      <div className="product-banner">
        <div className="w-100 h-100 d-flex flex-column justify-content-center ">
          <div className="products-outer text-center">
            <div className="product-heading mb-5 ">
              <h1>Frozen Food</h1>
            </div>
            {/* <div className="product-search mb-5 mx-auto max-600">
              <div className="product-search-bx">
                <form className="select-for select-for-2">
                  <div class="d-flex mb-3" data-flip="false">
                    <select
                      class="form-select form-sel all-cat"
                      aria-label="Default select example"
                    >

                      
                      
                      
                      <optgroup label="Services">
                        <option value="1">FDi Lead Generation</option>
                        <option value="2">Digital Marketing</option>
                        <option value="3">Trade Promotion Services</option>
                        <option value="4">Political Advisory & Public Relations</option>

                      </optgroup>
                      <optgroup label="Products">
                        <option value="Product1">Investment Intelligence Platform</option>
                        <option value="Product2">Trade Intelligence Platform</option>
                        <option value="Product3">FDi Institute</option>
                        <option value="Product3">FDi CRM</option>
                      </optgroup>
                    </select>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      aria-label="Username"
                    />
                    <button className="search-btn">
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div> */}
            {/* <div className="product-buttons mb-70">
              <Link className="nav-btn me-3" to="">
                Sign up for free
              </Link>
              <Link className="nav-btn btn-w-1 ms-3" to="">
                7 days free trial
              </Link>
            </div> */}
            <div className="products-slgans  w-100 mb-5">
              <div className="row  w-100">
                <div className="col-4">
                  <div className="slogan-icon">
                  <img src="https://cdn.iconscout.com/icon/free/png-256/free-samosa-1671395-1422401.png" ></img>             
                  </div>
                  <div className="slogan-heading">
                    <h6>Samosa</h6>
                  </div>
                </div>
                <div className="col-4">
                  <div className="slogan-icon">
                  <img src="https://cdn-icons-png.flaticon.com/512/7499/7499405.png" ></img>             
                  </div>
                  <div className="slogan-heading">
                    <h6>Dimsum</h6>
                  </div>
                </div>
                <div className="col-4 ">
                  <div className="slogan-icon">
                  <img src="https://cdn-icons-png.flaticon.com/512/3082/3082018.png" ></img>             
                  </div>
                  <div className="slogan-heading">
                    <h6>Sauces</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 pt-5 pb-4 ">
        <div className="contact-heading max-600 mx-auto text-center px-4">
          <h1>Products</h1>

          <h4>
          Explore Our World of Frozen Delights.
          Indulge in Handpicked Ingredients That Taste Like Home
          </h4>
        </div>
      </div>


      {/* certifications starts */}
      <div className="w-100 mb-5">
        <div className=" px-4">
      <h4 className="p-3">Samosa</h4>
          <div class="row">
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="">
                      <img src={ProductIcon} />
                    </i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Aloo Samosa</h1>
                  <p>
                    Aloo Samosa
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
                    Order Now <i class="fa-solid fa-right-long"> 
                    </i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="">
                      <img src={ProductIcon} />
                    </i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Butter chicken Samosa</h1>
                  <p>
                  Butter Chicken Samosa
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
Order Now <i class="fa-solid fa-right-long"></i> 
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="">
                      <img src={ProductIcon} />
                    </i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Chilly Chicken Samosa</h1>
                  <p>
                    Chilly Chicken Samosa
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
Order Now <i class="fa-solid fa-right-long"></i> 
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="">
                      <img src={ProductIcon} />
                    </i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Butter Paneer samosa</h1>
                  <p>
                    Butter Paneer Samosa
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
Order Now <i class="fa-solid fa-right-long"></i> 
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="">
                      <img src={ProductIcon} />
                    </i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Veg Cheese Samosa</h1>
                  <p>
                    Veg Cheese Samosa
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
Order Now <i class="fa-solid fa-right-long"></i> 
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="">
                      <img src={ProductIcon} />
                    </i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Kheema Samosa</h1>
                  <p>
                    Kheema Samosa
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
Order Now <i class="fa-solid fa-right-long"></i> 
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="">
                      <img src={ProductIcon} />
                    </i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Choop Samosa</h1>
                  <p>
                    Choop Samosa
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
Order Now <i class="fa-solid fa-right-long"></i> 
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="">
                      <img src={ProductIcon} />
                    </i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Nutella Samosa</h1>
                  <p>
                    Nutella Samosa
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
Order Now <i class="fa-solid fa-right-long"></i> 
                  </Link>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

      <div className="w-100 mb-5">
        <div className=" px-4">
      <h4 className="p-3">Sauces</h4>
          <div class="row">
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="">
                      <img src={ProductIcon3} />
                    </i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Butter Sauce</h1>
                  <p>
                    Butter Sauce
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
                    Order Now <i class="fa-solid fa-right-long"> 
                    </i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="">
                      <img src={ProductIcon1} />
                    </i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Chilli Sauce </h1>
                  <p>
                  Chilli Sauce
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
Order Now <i class="fa-solid fa-right-long"></i> 
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="">
                      <img src={ProductIcon4} />
                    </i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Tandoori Sauce</h1>
                  <p>
                    Tandoori Sauce
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
Order Now <i class="fa-solid fa-right-long"></i> 
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="">
                      <img src={ProductIcon1} />
                    </i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Schezwan Sauce</h1>
                  <p>
                    Schezwan Sauce
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
Order Now <i class="fa-solid fa-right-long"></i> 
                  </Link>
                </div>
              </div>
            </div>
           

          </div>
        </div>
      </div>

      <div className="w-100 mb-5">
        <div className=" px-4">
          <h4 className="p-3">Dimsum</h4>
           <div class="row">
             <div class="col-lg-4 mb-4">
               <div class="prod-bx shadow h-100 p-4 text-center">
                 <div class="prod-icon mb-4">
                   <span>
                     <i class="">
                       <img src={ProductIcon2} />
                     </i>
                   </span>
                 </div>
                 <div class="prod-head">
                   <h1>Dimsum</h1>
                   <p>
                     Dimsum
                   </p>
                 </div>
                 <div class="learn-more">
                   <Link to="/product-details">
                    Order Now <i class="fa-solid fa-right-long"> 
                    </i>
                   </Link>
                 </div>
               </div>
             </div>
             <div class="col-lg-4 mb-4">
               <div class="prod-bx shadow h-100 p-4 text-center">
                 <div class="prod-icon mb-4">
                   <span>
                     <i class="">
                       <img src={ProductIcon2} />
                     </i>
                   </span>
                 </div>
                 <div class="prod-head">
                   <h1>Chicken Momosi</h1>
                   <p>
                     Dimsum
                   </p>
                 </div>
                 <div class="learn-more">
                   <Link to="/product-details">
                    Order Now <i class="fa-solid fa-right-long"> 
                    </i>
                   </Link>
                 </div>
               </div>
             </div>
             <div class="col-lg-4 mb-4">
               <div class="prod-bx shadow h-100 p-4 text-center">
                 <div class="prod-icon mb-4">
                   <span>
                     <i class="">
                       <img src={ProductIcon2} />
                     </i>
                   </span>
                 </div>
                 <div class="prod-head">
                   <h1>Veg Momosi</h1>
                   <p>
                     Dimsum
                   </p>
                 </div>
                 <div class="learn-more">
                   <Link to="/product-details">
                    Order Now <i class="fa-solid fa-right-long"> 
                    </i>
                   </Link>
                 </div>
               </div>
             </div>
             <div class="col-lg-4 mb-4">
               <div class="prod-bx shadow h-100 p-4 text-center">
                 <div class="prod-icon mb-4">
                   <span>
                     <i class="">
                       <img src={ProductIcon2} />
                     </i>
                   </span>
                 </div>
                 <div class="prod-head">
                   <h1>Beef Momosi</h1>
                   <p>
                     Dimsum
                   </p>
                 </div>
                 <div class="learn-more">
                   <Link to="/product-details">
                    Order Now <i class="fa-solid fa-right-long"> 
                    </i>
                   </Link>
                 </div>
               </div>
             </div>
             <div class="col-lg-4 mb-4">
               <div class="prod-bx shadow h-100 p-4 text-center">
                 <div class="prod-icon mb-4">
                   <span>
                     <i class="">
                       <img src={ProductIcon2} />
                     </i>
                   </span>
                 </div>
                 <div class="prod-head">
                   <h1>Pork Momosi</h1>
                   <p>
                     Dimsum
                   </p>
                 </div>
                 <div class="learn-more">
                   <Link to="/product-details">
                    Order Now <i class="fa-solid fa-right-long"> 
                    </i>
                   </Link>
                 </div>
               </div>
             </div>
             <div class="col-lg-4 mb-4">
               <div class="prod-bx shadow h-100 p-4 text-center">
                 <div class="prod-icon mb-4">
                   <span>
                     <i class="">
                       <img src={ProductIcon2} />
                     </i>
                   </span>
                 </div>
                 <div class="prod-head">
                   <h1>Chocolate Momosi</h1>
                   <p>
                     Dimsum
                   </p>
                 </div>
                 <div class="learn-more">
                   <Link to="/product-details">
                    Order Now <i class="fa-solid fa-right-long"> 
                    </i>
                   </Link>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
      
      



      {/* <div className="w-100 mb-5">
        <div className="contact-heading max-600 mx-auto text-center px-4">
          <h1>Structural Design Software</h1>

          <p>
            We are a uniquely diverse range of consultants delivering extremely
            high profile projects across the world, working to meet some of the
            world’s greatest challenges.
          </p>
        </div>
        <div className=" px-4">
          <div class="row">
          
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="bi bi-box"></i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Structural 3D</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorum corporis itaque earum minima ex obcaecati, magnam
                    quidem doloremque assumenda architecto pariatur nostrum nam
                    deserunt suscipit, vero at maxime eos culpa.
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
                    Order Now <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="bi bi-box"></i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Structural 3D</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorum corporis itaque earum minima ex obcaecati, magnam
                    quidem doloremque assumenda architecto pariatur nostrum nam
                    deserunt suscipit, vero at maxime eos culpa.
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
                    Order Now <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}




      {/* <div class="gr-bg py-60">
        <div class="container z-ind-3">
          <div className="contact-heading max-700 mx-auto text-center px-4 ">
            <h1>Join 10,000+ Professional Engineers Worldwide!</h1>

            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo
              neque distinctio delectus nihil exercitationem nam labore
              voluptatum nobis similique.
            </p>
            <Link className="nav-btn" to="#">
              Get started for free
            </Link>
          </div>
        </div>
      </div> */}

      <Footer />
    </>
  );
}

export default Products;
