import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import "./PageNotFound.css"

function PageNotFound(){
    return(
        <>
        <Header />


        <div id="notfound">
		<div class="notfound">
			<div class="notfound-404">
				<h1>404</h1>
				<h2>Page not found</h2>
			</div>

		</div>
	</div>

        <Footer />
        
        </>
    )
}

export default PageNotFound