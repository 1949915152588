import React from "react";
import abt1 from "./../Images/website-images/1b.jpeg";
import abt2 from "./../Images/website-images/3b.jpeg";
import abt3 from "./../Images/website-images/2b.jpeg";
import abt4 from "./../Images/website-images/4b.jpeg";
import blog1 from "./../Images/Images/ablog1.jpeg";
import Frame2 from "../Pages/frame-2";
import Frame from "../Pages/frame";
// import { Link } from 'react-router-dom';

function Blogs() {
  return (
    <>
      <div class="row">
        <div class="col-lg-7">
          <a
          href="#"
            target="_blank"
            class="blog-box blog-bx "
          >
            <div class="blog-img">
              <img src={blog1} alt="" />
            </div>
            <div class="blog-date">
              {/* <span class="me-3">14-05-2023</span> */}
              <span></span>
            </div>
            <div class="blog-text">
              <h1>
                {" "}
                Ticket to Canada Now Possible With Bedi Group
              </h1>
              <p>
                {/* Max Cantilever upto 8m Congratulations to team IntelBUILD
                Engineering Services LLP BILAL HAJI Aamir Parihar Gauri Kundnani
                Atikah Baig */}
              </p>
            </div>
          </a>
        </div>
        <div class="col-lg-5 side-blog">
          <div class="scroll-left">
            <a
            href="#"
              target="_blank"
              className="blog-box blg-sm-line"
            >
              <div class="row">
                <div class="col-4">
                  <div class="small-blg-img">
                    <img src={blog1} alt="" />
                  </div>
                </div>
                <div class="col-8">
                  <div class="blog-date">
                    <span class="me-3"></span>
                    <span></span>
                  </div>
                  <div class="blog-text">
                    <h1>
                      lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </h1>
                  </div>
                </div>
              </div>
            </a>
            <a
              href="#"
              target="_blank"
              className="blog-box blg-sm-line"
            >
              <div class="row">
                <div class="col-4">
                  <div class="small-blg-img">
                    <img src={blog1} alt="" />
                  </div>
                </div>
                <div class="col-8">
                  <div class="blog-date">
                    {/* <span class="me-3">10-5-2023</span> */}
                    {/* <span>Category</span> */}
                  </div>
                  <div class="blog-text">
                    <h1>
                      Believing neglected so so allowance existence departure.
                    </h1>
                  </div>
                </div>
              </div>
            </a>
            <a
              href="#"
              target="_blank"
              className="blog-box blg-sm-line"
            >
              <div class="row">
                <div class="col-4">
                  <div class="small-blg-img">
                    <img src={blog1} alt="" />
                  </div>
                </div>
                <div class="col-8">
                  <div class="blog-date">
                    {/* <span class="me-3">18-4-2023</span> */}
                    {/* <span>Category</span> */}
                  </div>
                  <div class="blog-text">
                    <h1>
                      Believing neglected so so allowance existence departure.
                    </h1>
                  </div>
                </div>
              </div>
            </a>
            <a
              href="#"
              target="_blank"
              className="blog-box blg-sm-line"
            >
              <div class="row">
                <div class="col-4">
                  <div class="small-blg-img">
                    <img src={blog1} alt="" />
                  </div>
                </div>
                <div class="col-8">
                  <div class="blog-date">
                    {/* <span class="me-3">18-4-2023</span>
                    <span>Category</span> */}
                  </div>
                  <div class="blog-text">
                    <h1>
                      Believing neglected so so allowance existence departure.
                    </h1>
                  </div>
                </div>
              </div>
            </a>
            <a
              href="#"
              target="_blank"
              className="blog-box blg-sm-line"
            >
              <div class="row">
                <div class="col-4">
                  <div class="small-blg-img">
                    <img src={blog1} alt="" />
                  </div>
                </div>
                <div class="col-8">
                  <div class="blog-date">
                    {/* <span class="me-3">18-4-2023</span>
                    <span>Category</span> */}
                  </div>
                  <div class="blog-text">
                    <h1>
                      Believing neglected so so allowance existence departure.
                    </h1>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        

        <div class="row w-100 mx-0">
         
        </div>
        
      </div>
    </>
  );
}

export default Blogs;
