import "./App.css";
import { useState} from "react";
import RouterPage from "./Components/RouterPage";
import FloatingPopup from "./Components/FloatingPopup";
import "./styles/styles.css";

function App() {
  const [buttonPopup, setButtonPopup] = useState(false);

  return (
    <div className="App">
      {/* <Suspense fallback={<p>Loading application...</p>}> */}
      {/* <button
        style={{
          height: "3.5rem",
          width: "3.5rem",
          fontSize: "2.2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => setButtonPopup(true)}
        className="float-btn"
      >
        <i class={"bi bi-chat-fill"}></i>
      </button> */}
      <FloatingPopup
        trigger={buttonPopup}
        setTrigger={setButtonPopup}
      ></FloatingPopup>
      <RouterPage />
      {/* </Suspense> */}
    </div>
  );
}

export default App;
