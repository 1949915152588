import React, { useState } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import BilalHaji from "./../Images/bilal-haji.jpeg";
// import Quote from "./../Images/quote-2.png";

//images for our team
import Member from "./../Images/Team Member/member 0.png";
import Founder from "./../Images/Team Member/1founder.jpg";
import Marketing1 from "./../Images/Team Member/1marketing.jpeg";
import Marketing2 from "./../Images/Team Member/2marketing.jpg";



import "react-html5video/dist/styles.css";
// import ReactReadMoreReadLess from "react-read-more-read-less";
import SecondaryMenu from "./SecondaryMenu";
import Img1 from "./../Images/alain-pham-P_qvsF7Yodw-unsplash.jpg";
import ParallaxSmallBanner from "../SubComponents/ParallaxSmallBanner";
import OfficeImage1 from "../Images/office-images/OfficeImage1.jpg";
import OfficeImage2 from "../Images/office-images/OfficeImage2.jpg";
import OfficeImage3 from "../Images/office-images/OfficeImage3.jpg";
import OfficeImage4 from "../Images/office-images/OfficeImage4.jpg";
import OfficeImage5 from "../Images/office-images/OfficeImage5.jpg";
import OfficeImage6 from "../Images/office-images/OfficeImage6.jpg";
import OfficeImage7 from "../Images/office-images/OfficeImage7.jpg";
import OfficeImage8 from "../Images/office-images/OfficeImage8.jpg";
// import OfficeImage7 from "../Images/office-images/img/OfficeImage (1).jpg";
// import OfficeImage8 from "../Images/office-images/img/OfficeImage (2).jpg";
import OfficeImage9 from "../Images/office-images/img/OfficeImage (3).jpg";
import OfficeImage10 from "../Images/office-images/img/OfficeImage (4).jpg";
import OfficeImage11 from "../Images/office-images/img/OfficeImage (5).jpg";
import OfficeImage12 from "../Images/office-images/img/OfficeImage (6).jpg";
import OfficeImage13 from "../Images/office-images/img/OfficeImage (7).jpg";
import OfficeImage14 from "../Images/office-images/img/OfficeImage (8).jpg";
import OfficeImage15 from "../Images/office-images/img/OfficeImage (9).jpg";
import OfficeImage16 from "../Images/office-images/img/OfficeImage (10).jpg";
import OfficeImage17 from "../Images/office-images/img/OfficeImage (1).jpg";
// team member
import HuzaifaNakhwa from "./../Images/Team Member/huzaifa-nakhwa.png";
import RizwanNakhwa from "./../Images/Team Member/rizwan-nakhwa.png";
// import TeamMember01 from "./../Images/Team Member/huzaifa-nakhwa.jpeg";
// import TeamMember02 from "./../Images/Team Member/rizwan-nakhwa.jpeg";
import TeamMember03 from "./../Images/Team Member/Aamir Parihar.png";
// import TeamMember04 from "./../Images/Team Member/";
import TeamMember05 from "./../Images/Team Member/bilal haji.png"
import TeamMember06 from "./../Images/Team Member/Abdul Wajid.png";
// import TeamMember07 from "./../Images/Team Member/";
import TeamMember08 from "./../Images/Team Member/sandesh k.png";
import TeamMember09 from "./../Images/Team Member/vishal patil.png";
import TeamMember10 from "./../Images/Team Member/akshay joil.png";
import TeamMember11 from "./../Images/Team Member/haresh.png";
import TeamMember12 from "./../Images/Team Member/samad.png";
import TeamMember13 from "./../Images/Team Member/sameer shaikh.png";
import TeamMember14 from "./../Images/Team Member/md.kaif kasam.png";
import TeamMember15 from "./../Images/Team Member/Atikah Baig.png";
import TeamMember16 from "./../Images/Team Member/saad_nadaf.png";
// team member

import "./style.css";
import FilterTeamsTab from "./FilterTeamsTab";
import OurTeamModel from "./OurTeamModel";
import { Carousel } from "react-bootstrap";
function OurTeam(props) {

  const [index, setIndex] = useState(0);

  // const handleSelect = (selectedIndex) => {
  //   setIndex(selectedIndex);
  // };
  const [index2, setIndex2] = useState(0);

  // const handleSelect2 = (selectedIndex) => {
  //   setIndex2(selectedIndex);
  // };
  function previous() {
    if (index === 0) {
      setIndex(4);
      setIndex2(4);
    } else {
      setIndex(index - 1);
      setIndex2(index - 1);
    }
  }
  function next() {
    if (index === 4) {
      setIndex(0);
      setIndex2(0);
    } else {
      setIndex(index + 1);
      setIndex2(index + 1);
    }
  }

  const teamDataManager = [
    {
      name: "Member",
      img: Member,
      designation: "Senior Structural Designer",
      description:
        "With a Degree in Commerce and an experience of above 15 years in Civil Industry, Mr. Aamir Parihar had been practically engaged in some of the most prestigious client base in past & ongoing. Leading the family business of ageing more than 6 Decades Mr. Aamir has a diversified knowledge in Negotiating/Execution and Team Building, with resources of all types in the civil industry. He has successfully completed high valued projects in steel building Design & Built formats and more than 3 Million Sft in RCC Structure. More than A Million sft in civil finishing, which includes Landscape/Hardscape & Ornamental work in Sand Stone for Dry/Wet Cladding for 5 Star Hotels & Commercial Space.",
      category: "all",
    },
 
  ];
  const teamDataHead = [
    
    {
      name: "MR Bedi",
      img: Founder,
      designation: "President and Founder",
      description:
        "Progressive & Reforming are the words that perfectly describe Mr. Rizwan Nakhwa. As a qualified Engineer and acting Managing Director, Mr. Rizwan Nakhwa is well-known in the community for the fresh and professional approach he brings to the table. He has been the key factor in surpassing intelBUILD’s mission to his forward-looking thinking fuelled with his fierce dedication.",
        category: "all",
    },
    
    // {
    //   name: "Member",
    //   img: Member,
    //   // designation: "Project Technical Advisor",
    //   description:
    //     "Progressive & Reforming are the words that perfectly describe Mr. Rizwan Nakhwa. As a qualified Engineer and acting Managing Director, Mr. Rizwan Nakhwa is well-known in the community for the fresh and professional approach he brings to the table. He has been the key factor in surpassing intelBUILD’s mission to his forward-looking thinking fuelled with his fierce dedication.",
    //     category: "all",
    // },
    
    // {
    //   name: "Member",
    //   img: Member,
    //   // designation: "Project Technical Advisor",
    //   description:
    //     "Progressive & Reforming are the words that perfectly describe Mr. Rizwan Nakhwa. As a qualified Engineer and acting Managing Director, Mr. Rizwan Nakhwa is well-known in the community for the fresh and professional approach he brings to the table. He has been the key factor in surpassing intelBUILD’s mission to his forward-looking thinking fuelled with his fierce dedication.",
    //     category: "all",
    // },
    
  ];
  
  const teamDataStructuralEngg = [
   
  ];
  const teamDataExecution = [
    // {
    //   name: "member",
    //   img: Member,
    //   designation: "Site Manager",
    //   description:
    //     "Progressive & Reforming are the words that perfectly describe Mr. Jhon Doe. As a qualified Engineer and acting Managing Director, Mr. Jhon Doe is well-known in the community for the fresh and professional approach he brings to the table. He has been the key factor in surpassing intelBUILD’s mission to his forward-looking thinking fuelled with his fierce dedication.",
    //   category: "all",
    // },
  ];
  const teamDataArchitect = [
    {
      name: "Niharika Kaur",
      img: Member,
      designation: "Marketing Manager",
      description:
        "Progressive & Reforming are the words that perfectly describe Mr. Jhon Doe. As a qualified Engineer and acting Managing Director, Mr. Jhon Doe is well-known in the community for the fresh and professional approach he brings to the table. He has been the key factor in surpassing intelBUILD’s mission to his forward-looking thinking fuelled with his fierce dedication.",
      category: "all",
    },
    // {
    //   name: "member",
    //   img: Member,
    //   designation: "Designation",
    //   description:
    //     "Progressive & Reforming are the words that perfectly describe Mr. Jhon Doe. As a qualified Engineer and acting Managing Director, Mr. Jhon Doe is well-known in the community for the fresh and professional approach he brings to the table. He has been the key factor in surpassing intelBUILD’s mission to his forward-looking thinking fuelled with his fierce dedication.",
    //   category: "all",
    // },
    // {
    //   name: "member",
    //   img: Member,
    //   designation: "Designation",
    //   description:
    //     "Progressive & Reforming are the words that perfectly describe Mr. Jhon Doe. As a qualified Engineer and acting Managing Director, Mr. Jhon Doe is well-known in the community for the fresh and professional approach he brings to the table. He has been the key factor in surpassing intelBUILD’s mission to his forward-looking thinking fuelled with his fierce dedication.",
    //   category: "all",
    // },
   

  ];

  const teamDataStaff = [

    
    
   
    {
      name: "Sonam Jain",
      img: Marketing1,
      designation: "Marketing Manager",
      description:
        "Progressive & Reforming are the words that perfectly describe Mr. Rizwan Nakhwa. As a qualified Engineer and acting Managing Director, Mr. Rizwan Nakhwa is well-known in the community for the fresh and professional approach he brings to the table. He has been the key factor in surpassing intelBUILD’s mission to his forward-looking thinking fuelled with his fierce dedication.",
        category: "all",
    },
    
   
    {
      name: "Gunpreet Singh Khurana",
      img: Member,
      designation: "Marketing Manager",
      description:
        "Progressive & Reforming are the words that perfectly describe Mr. Rizwan Nakhwa. As a qualified Engineer and acting Managing Director, Mr. Rizwan Nakhwa is well-known in the community for the fresh and professional approach he brings to the table. He has been the key factor in surpassing intelBUILD’s mission to his forward-looking thinking fuelled with his fierce dedication.",
        category: "all",
    },
    
   
    // {
    //   name: "Member",
    //   img: Member,
    //   // designation: "Project Technical Advisor",
    //   description:
    //     "Progressive & Reforming are the words that perfectly describe Mr. Rizwan Nakhwa. As a qualified Engineer and acting Managing Director, Mr. Rizwan Nakhwa is well-known in the community for the fresh and professional approach he brings to the table. He has been the key factor in surpassing intelBUILD’s mission to his forward-looking thinking fuelled with his fierce dedication.",
    //     category: "all",
    // },
    
    
  ];
  const [teams, setTeams] = useState([
    ...teamDataManager,
    ...teamDataHead,
    ...teamDataStaff,
    ...teamDataStructuralEngg,
    ...teamDataExecution,
    ...teamDataArchitect,
  ]);

  const [modelData, setModelData] = useState({});
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [activeTeam, setActiveTeam] = useState("all");
  function setAllTems() {
    setActiveTeam("all");
    setTeams([
      ...teamDataManager,
      ...teamDataHead,
      ...teamDataStaff,
      ...teamDataStructuralEngg,
      ...teamDataExecution,
      ...teamDataArchitect,
    ]);
  }
  function setManagerTems() {
    setActiveTeam("managers");
    const manager = teamDataManager;
    setTeams(manager);
  }
  function setHeadTems() {
    setActiveTeam("heads");
    const head = teamDataHead;
    setTeams(head);
  }
  function setStaffTems() {
    setActiveTeam("staff");
    const head = teamDataStaff;
    setTeams(head);
  }
  function setStructuralTems() {
    setActiveTeam("structural");
    const head = teamDataStructuralEngg;
    setTeams(head);
  }
  function setExecutionTems() {
    setActiveTeam("execution");
    const head = teamDataExecution;
    setTeams(head);
  }
  function setArchitectTems() {
    setActiveTeam("architect");
    const head = teamDataArchitect;
    setTeams(head);
  }
  return (
    <>
      <Header />
      <div className="scrll">
        <ParallaxSmallBanner img={Img1} title="Our Team" />
      </div>
      <OurTeamModel
        show={show}
        handleClose={handleClose}
        modelData={modelData}
      />
      <div className="pos-rel ourTeamSection">
        <div className="w-100 py-5 pb-0 pad-60">
          <div className="pt-2 pb-5 text-center">
            <SecondaryMenu linkName={props.linkName} />
          </div>
          <FilterTeamsTab 
            setAllTems={setAllTems}
            setManagerTems={setManagerTems}
            setHeadTems={setHeadTems}
            setStaffTems={setStaffTems}
            setStructuralTems={setStructuralTems}
            setExecutionTems={setExecutionTems}
            setArchitectTems={setArchitectTems}
            activeTeam={activeTeam}
            />
            {/* {(activeTeam === "managers" || activeTeam === "all") && <div className="container">
            <span className="manager d-flex">
              <span>Management Team</span>
              <hr className="team-ruler"/>
            </span>
            <div className="row justify-content-center mb-5">
              {teamDataManager.map((team, index) => {
                return (
                  <div
                    onClick={() => {
                      setModelData(team);
                      handleShow();
                    }}
                    className="column"
                  >
                    <div className="team-3">
                      <div className="team-img">
                        <img
                          src={team.img}
                          className={`${
                            team.name === "Bilal Haji" ? "bilal" : ""
                          }`}
                          alt="Team-1"
                        />
                        <div className="team-overlay text-white d-flex justify-content-center align-items-center flex-column">
                         //<h4>{team.name}</h4> 
                        <p className="m-0 text-wrap">Master's in <br/>Structural Engineering</p>
                      </div>
                      </div>

                      <div className={`team-content`}>
                          <h2>{team.name}</h2>
                          <h3>{team.designation}</h3>
                        </div>
                      
                    </div>
                  </div>
                );
              })}
            </div>
          </div>} */}
          {(activeTeam === "heads" || activeTeam === "all") &&<div className="container">
            <span className="manager d-flex mt-5">
              <span>Founders</span>
              <hr className="team-ruler"/>
            </span>
            <div className="row justify-content-center mb-5">
              {teamDataHead.map((team, index) => {
                return (
                  <div
                    onClick={() => {
                      setModelData(team);
                      handleShow();
                    }}
                    className="column"
                  >
                    <div className="team-3">
                      <div className="team-img">
                        <img
                          src={team.img}
                          className={`${
                            team.name === "Bilal Haji" ? "bilal" : ""
                          }`}
                          alt="Team-1"
                        />
                        <div className="team-overlay text-white d-flex justify-content-center align-items-center flex-column">
                        {/* <h4>{team.name}</h4> */}
                        <h4>{team.designation}</h4>
                        {/* <p className="m-0 text-wrap">{team.designation}</p> */}
                      </div>
                      </div>
                      <div className={`team-content`}>
                          <h2>{team.name}</h2>
                          {/* <h3>{team.designation}</h3> */}
                        </div>
                      
                    </div>
                  </div>
                );
              })}
            </div>
          </div>}
       
          {/* {(activeTeam === "structural" || activeTeam === "all") && <div className="container">
            <span className="manager d-flex mt-4">
              <span>Structural Engineering Department</span>
              <hr className="team-ruler"/>
            </span>
            <div className="row justify-content-center ">
              {teamDataStructuralEngg.map((team, index) => {
                return (
                  <div onClick={() => {
                    setModelData(team);
                    handleShow();
                  }} className="column">
                    <div className="team-3">
                      <div className="team-img">
                        <img
                          src={team.img}
                          className={`${
                            team.name === "Bilal Haji" ? "bilal" : ""
                          }`}
                          alt="Team-1"
                        /><div className="team-overlay text-white d-flex justify-content-center align-items-center flex-column">
                           </div>
                      </div>
                      <div className={`team-content`}>
                          <h2>{team.name}</h2>
                          <h3>{team.designation}</h3>
                        </div>
                      
                    </div>
                  </div>
                );
              })}
            </div>
          </div>} */}

          {(activeTeam === "staff" || activeTeam === "all") && <div className="container">
            <span className="manager d-flex mt-4">
              <span> Marketing Team</span>
              <hr className="team-ruler"/>
            </span>
            <div className="row justify-content-center ">
              {teamDataStaff.map((team, index) => {
                return (
                  <div onClick={() => {
                    setModelData(team);
                    handleShow();
                  }} className="column">
                    <div className="team-3">
                      <div className="team-img">
                        <img
                          src={team.img}
                          className={`${
                            team.name === "Bilal Haji" ? "bilal" : ""
                          }`}
                          alt="Team-1"
                        />
                        <div className="team-overlay text-white d-flex justify-content-center align-items-center flex-column">
                        {/* <h4>{team.name}</h4>
                        <p className="m-0 text-wrap">{team.designation}</p> */}
                            <h4>{team.designation}</h4>
                      </div>
                      </div>
                      <div className={`team-content`}>
                          <h2>{team.name}</h2>
                          {/* <h3>{team.designation}</h3> */}
                        </div>
                      
                    </div>
                  </div>
                );
              })}
            </div>
          </div>}

{/*           
          {(activeTeam === "execution" || activeTeam === "all") && <div className="container">
            <span className="manager d-flex mt-4">
              <span>Execution Department</span>
              <hr className="team-ruler"/>
            </span>
            <div className="row justify-content-center ">
              {teamDataExecution.map((team, index) => {
                return (
                  <div onClick={() => {
                    setModelData(team);
                    handleShow();
                  }} className="column">
                    <div className="team-3">
                      <div className="team-img">
                        <img
                          src={team.img}
                          className={`${
                            team.name === "Bilal Haji" ? "bilal" : ""
                          }`}
                          alt="Team-1"
                        /><div className="team-overlay text-white d-flex justify-content-center align-items-center flex-column">
                             </div>
                      </div>
                      <div className={`team-content`}>
                          <h2>{team.name}</h2>
                          <h3>{team.designation}</h3>
                        </div>
                      
                    </div>
                  </div>
                );
              })}
            </div>
          </div>} */}
          
          {(activeTeam === "architect" || activeTeam === "all") && <div className="container">
            <span className="manager d-flex mt-4">
              <span>Accounts & Supply Chain Department</span>
              <hr className="team-ruler"/>
            </span>
            <div className="row justify-content-center ">
              {teamDataArchitect.map((team, index) => {
                return (
                  <div onClick={() => {
                    setModelData(team);
                    handleShow();
                  }} className="column">
                    <div className="team-3">
                      <div className="team-img">
                        <img
                          src={team.img}
                          className={`${
                            team.name === "Bilal Haji" ? "bilal" : ""
                          }`}
                          alt="Team-1"
                        /><div className="team-overlay text-white d-flex justify-content-center align-items-center flex-column">
                        {/* <h4>{team.name}</h4>
                        <p className="m-0 text-wrap">{team.designation}</p> */}
                            <h4>{team.designation}</h4>
                      </div>
                      </div>
                      <div className={`team-content`}>
                          <h2>{team.name}</h2>
                          {/* <h3>{team.designation}</h3> */}
                        </div>
                      
                    </div>
                  </div>
                );
              })}
            </div>
          </div>}
        </div>
          <div class="container mb-4">
          <span className="the-team manager d-flex mt-4">
          <hr className="team-ruler"/>
          <span className="text-nowrap">Behind The Scene</span>
          <hr className="team-ruler"/>
          <div class="ms-auto d-flex flex-nowrap">
            <button style={{background: "#be8400"}} className="btn" onClick={previous}>
              <img src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e" style={{width: "1rem", height: "1rem"}} alt="left"/>
            </button>
            <button style={{background: "#be8400"}} className="btn ms-2" onClick={next}>
              <img src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e" style={{width: "1rem", height: "1rem"}} alt="right"/>
            </button>
          </div>
          <hr className="team-ruler me-0"/>
        </span>
        <Carousel
            indicators={false}
            activeIndex={index}
            fade
            className="d-none d-xl-block"
          >
            <Carousel.Item className="">
              <div className="officeSliderImages d-flex flex-wrap">
                <img
                  className="d-block"
                  src={OfficeImage1}
                  alt="Certificate1"
                />
                <img
                  className="d-block"
                  src={OfficeImage2}
                  alt="Certificate2"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="officeSliderImages d-flex flex-wrap">
                <img
                  className="d-block"
                  src={OfficeImage3}
                  alt="Certificate1"
                />
                <img
                  className="d-block"
                  src={OfficeImage4}
                  alt="Certificate2"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="officeSliderImages d-flex flex-wrap">
                <img
                  className="d-block"
                  src={OfficeImage5}
                  alt="Certificate1"
                />
                <img
                  className="d-block"
                  src={OfficeImage6}
                  alt="Certificate2"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="officeSliderImages d-flex flex-wrap">
                <img
                  className="d-block"
                  src={OfficeImage7}
                  alt="Certificate1"
                />
                <img
                  className="d-block"
                  src={OfficeImage8}
                  alt="Certificate2"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="officeSliderImages d-flex flex-wrap">
                <img
                  className="d-block"
                  src={OfficeImage1}
                  alt="Certificate1"
                />
                <img
                  className="d-block"
                  src={OfficeImage2}
                  alt="Certificate2"
                />
              </div>
            </Carousel.Item>
        </Carousel>
        <Carousel
            indicators={false}
            activeIndex={index2}
            fade
            className="d-none d-xl-block"
          >
            <Carousel.Item className="">
              <div className="officeSliderImages d-flex flex-wrap">
                <img
                  className="d-block"
                  src={OfficeImage3}
                  alt="Certificate1"
                />
                <img
                  className="d-block"
                  src={OfficeImage4}
                  alt="Certificate2"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="officeSliderImages d-flex flex-wrap">
                <img
                  className="d-block"
                  src={OfficeImage5}
                  alt="Certificate1"
                />
                <img
                  className="d-block"
                  src={OfficeImage6}
                  alt="Certificate2"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="officeSliderImages d-flex flex-wrap">
                <img
                  className="d-block"
                  src={OfficeImage7}
                  alt="Certificate1"
                />
                <img
                  className="d-block"
                  src={OfficeImage8}
                  alt="Certificate2"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="officeSliderImages d-flex flex-wrap">
                <img
                  className="d-block"
                  src={OfficeImage1}
                  alt="Certificate1"
                />
                <img
                  className="d-block"
                  src={OfficeImage2}
                  alt="Certificate2"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="officeSliderImages d-flex flex-wrap">
                <img
                  className="d-block"
                  src={OfficeImage3}
                  alt="Certificate1"
                />
                <img
                  className="d-block"
                  src={OfficeImage4}
                  alt="Certificate2"
                />
              </div>
            </Carousel.Item>
        </Carousel>
        <Carousel
            indicators={false}
            activeIndex={index}
            fade
            className="d-block d-xl-none"
          >
            <Carousel.Item className="">
              <div className="officeSliderImages d-flex flex-wrap">
                <img
                  className="d-block"
                  src={OfficeImage1}
                  alt="Certificate1"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="officeSliderImages d-flex flex-wrap">
                <img
                  className="d-block"
                  src={OfficeImage2}
                  alt="Certificate1"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="officeSliderImages d-flex flex-wrap">
                <img
                  className="d-block"
                  src={OfficeImage3}
                  alt="Certificate2"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="officeSliderImages d-flex flex-wrap">
                <img
                  className="d-block"
                  src={OfficeImage4}
                  alt="Certificate1"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="officeSliderImages d-flex flex-wrap">
                <img
                  className="d-block"
                  src={OfficeImage5}
                  alt="Certificate1"
                />
              </div>
            </Carousel.Item>
        </Carousel>
          
        
        </div>
        
        
        {/* <FilterTeamsTab 
            setAllTems={setAllTems}
            setManagerTems={setManagerTems}
            setHeadTems={setHeadTems}
            setStaffTems={setStaffTems}
            activeTeam={activeTeam}
            />
          <div className="container">
            <div className="row justify-content-center gap-5">
              {
                teams.map((team, index) => {
                  return(
                    <div className="column">
              <div className="team-3">
                <div className="team-img">
                  <img src={team.img} alt="Team-1" />
                </div>
                <div className="team-content">
                  <h2>{team.name}</h2>
                  <h3>{team.designation}</h3>
                  <div className="team-social">
                    <a className="social-tw" href="#">
                      <i className="fab fa-twitter" />
                    </a>
                    <a className="social-fb" href="#">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a className="social-li" href="#">
                      <i className="fab fa-linkedin-in" />
                    </a>
                    <a className="social-in" href="#">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                  <div className="team-overlay">
                    <button className="btn" onClick={()=>{
                      handleShow()
                      setModelData(team)
                    }}>Read Info</button>
                    <div className="team-social">
                      <a className="social-tw" href="#">
                        <i className="fab fa-twitter" />
                      </a>
                      <a className="social-fb" href="#">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a className="social-li" href="#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                      <a className="social-in" href="#">
                        <i className="fab fa-instagram" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                  )
              })
            }
            </div>
          </div> */}
      </div>

      <Footer />
      {/* </div> */}
    </>
  );
}

export default OurTeam;
