import ProjectView1 from "../Pages/ProjectView1";



const ProductsData =[
    {
        id:1,
        Data:{
            p1:"“Bedi Group” embarked on a unique real estate project with the establishment of the “Biryani Bar” restaurants. Our vision was to create a space that not only served delectable food but also provided a warm and welcoming atmosphere for all our patrons.",
            p2:"Our team meticulously planned every aspect of the project, from selecting the perfect locations to designing the interiors that reflect the vibrant culture and rich culinary traditions of India. Each “Biryani Bar” restaurant is a testament to our commitment to quality and attention to detail.",
            p3:"We took care of everything, ensuring a seamless execution of the project. This included navigating regulatory requirements, coordinating with contractors, and managing timelines. Our hands-on approach ensured that each restaurant was completed on time and within budget.",
            p4:"Today, “Biryani Bar” restaurants stand as a symbol of our dedication and hard work. They represent our passion for bringing authentic Indian cuisine to the North American market and our commitment to creating spaces that enhance community and connection",
            image1:"assets/website-images/Oil-and-Gas-pics/biryanibar.jpg",
            image2:"assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
        },
        service:"Project Biryani Bar",
        product:"Biryani Bar",
        category:"Real Estate",
        image:"assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
        span: "Bedi Group successfully established six franchises of Biryani Bar, expanding its presence in the real estate market. ",        
    },
    // {
    //     id:2,
    //     service:"1004 Colborne",
    //     product:"house",
    //     category:"Real Estate",
    //     image:"assets/website-images/Oil-and-Gas-pics/20 CH.jpeg",
    //     span: "Concluding a successful real estate project, Bedi Group showcases its commitment to excellence and innovation in property development. "
    // },
    {
        id:3,
        Data:{
            p1:"Bedi Group undertook an ambitious real estate project at 193 Drumond Street, a testament to our commitment to excellence and innovation in the field of real estate development.",
            p2:"Our team meticulously planned every aspect of the project, from the initial site selection to the final construction. We ensured that the project adhered to all regulatory requirements and was completed within the stipulated timeline and budget.",
            p3:"We took care of everything, from coordinating with contractors and architects to managing the construction process. Our hands-on approach ensured that every detail was taken care of, resulting in a project that met our high standards of quality and design.",
            p4:"Today, the project at 193 Drumond Street stands as a symbol of our dedication and hard work. It represents our passion for creating spaces that enhance community and connection, and our commitment to delivering projects that exceed expectations.",
            image1:"assets/website-images/Oil-and-Gas-pics/193D.jpeg",
            image2:"assets/website-images/Oil-and-Gas-pics/193D6.jpeg",
        },
        service:"193 Drumond Street",
        product:"house",
        category:"Real Estate",
        image:"assets/website-images/Oil-and-Gas-pics/193D.jpeg",
        span: "Concluding a successful real estate project, Bedi Group showcases its commitment to excellence and innovation in property development. "
    },
    {
        id:4,
        Data:{
            p1:"Bedi Group embarked on a significant real estate project at 20 Charles Street, demonstrating our commitment to delivering high-quality real estate solutions.",
            p2:"Our team meticulously planned every detail of the project, from site selection to final construction. We ensured that all regulatory requirements were met and that the project was completed within the agreed timeline and budget.",
            p3:"We took care of everything, managing all aspects of the project from coordinating with contractors and architects to overseeing the construction process. Our hands-on approach ensured that every detail was addressed, resulting in a project that met our high standards of quality and design.",
            p4:"Today, the project at 20 Charles Street stands as a testament to our dedication and hard work. It showcases our passion for creating spaces that enhance community and connection, and our commitment to delivering projects that exceed expectations.",
            image1:"assets/website-images/Oil-and-Gas-pics/20 CHARLES.jpeg",
            image2:"assets/website-images/Oil-and-Gas-pics/20 CH.jpeg",      
        },
        service:"20 Charles Street",
        product:"IOCL Assam",
        category:"Real Estate",
        image:"assets/website-images/Oil-and-Gas-pics/20 CH.jpeg",
        span: "Concluding a successful real estate project, Bedi Group showcases its commitment to excellence and innovation in property development. "
    },
    // {
    //     id:5,
    //     service:"Mount Pleasant",
    //     product:"IOCL Assam",
    //     category:"Real Estate",
    //     image:"assets/website-images/Oil-and-Gas-pics/20 CH.jpeg",
    //     span: "Concluding a successful real estate project, Bedi Group showcases its commitment to excellence and innovation in property development. "
    // },
    // {
    //     id:6,
    //     service:"19 Aderson Wood Stock",
    //     product:"IOCL Assam",
    //     category:"Real Estate",
    //     image:"assets/website-images/Oil-and-Gas-pics/20 CH.jpeg",
    //     span: "Concluding a successful real estate project, Bedi Group showcases its commitment to excellence and innovation in property development. "
    // },
    // {
    //     id:7,
    //     service:"West Lake",
    //     product:"IOCL Assam",
    //     category:"Real Estate",
    //     image:"assets/website-images/Oil-and-Gas-pics/20 CH.jpeg",
    //     span: "Concluding a successful real estate project, Bedi Group showcases its commitment to excellence and innovation in property development. "
    // },
    // {
    //     id:8,
    //     service:"Project Colborne",
    //     product:"IOCL Assam",
    //     category:"Real Estate",
    //     image:"assets/website-images/Oil-and-Gas-pics/20 CH.jpeg",
    //     span: "Bedi Group successfully established six franchises of Biryani Bar, expanding its presence in the real estate market. "
    // },
    // {
    //     id:9,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"bim",
    //     image:"assets/website-images/BIM-pics/bim4.png"
    // },
    // {
    //     id:10,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/BIM-pics/bim5.png"
    // },
    // {
    //     id:11,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/BIM-pics/bim6.png"
    // },
    // {
    //     id:12,
    //     service:"PEB Shed at Angola",
    //     product:"PEB-Shed-at-Angola",
    //     category:"peb-structures",
    //     image:"assets/website-images/Industrial-PEB-Projects/04_PEB-Shed-at-Angola.jpeg"
    // },
    // {
    //     id:13,
    //     service:"PEB",
    //     product:"PEB Structure",
    //     category:"peb-structures",
    //     image:"assets/website-images/Industrial-PEB-Projects/peb1.jpg"
    // },
    // {
    //     id:14,
    //     service:"PEB Shed at Odisha",
    //     product:"PEB-Shed-at-Angola",
    //     category:"peb-structures",
    //     image:"assets/website-images/Industrial-PEB-Projects/PEB-Shed-at-Odhisa.jpg"
    // },
    // {
    //     id:15,
    //     service:"PEB Shed at Malad",
    //     product:"PEB-Shed-at-Angola",
    //     category:"peb-structures",
    //     image:"assets/website-images/Industrial-PEB-Projects/Shed-at-malad.jpeg"
    // },
    // {
    //     id:16,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate1.jpeg"
    // },
    // {
    //     id:17,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/Bungalow-@-Yavatamal.jpg"
    // },
    // {
    //     id:18,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/Bungalow-@-Yavatamal2.jpg"
    // },
    // {
    //     id:19,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/Bungalow-@-Yavatamal1.jpg"
    // },
    // {
    //     id:20,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/Bungalow-@-Yavatamal2.jpg"
    // },
    // {
    //     id:21,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate.jpeg"
    // },
    // {
    //     id:22,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate2.jpeg"
    // },
    // {
    //     id:23,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate3.jpeg"
    // },
    // {
    //     id:24,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate4.jpg"
    // },
    // {
    //     id:25,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate5.jpg"
    // },
    // {
    //     id:26,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/shams-residency.jpg"
    // },
    // {
    //     id:27,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate6.jpeg"
    // },

    // // .........................Audit
    // {
    //     id:28,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test.jpeg"
    // },
   
    // {
    //     id:29,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test1.jpeg"
    // },
   
    // {
    //     id:30,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test2.jpeg"
    // },
   
    // {
    //     id:31,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test3.jpeg"
    // },
   
    // {
    //     id:32,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test4.jpeg"
    // },
   
    // {
    //     id:33,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test5.jpeg"
    // },
  
    // {
    //     id:26,
    //     service:"Oxygen Plant at Mahul Chembur",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Industrial-PEB-Projects/Oxygen-Plant-at-Mahul-Chembur.png"
    // },

    // sus
    // {
    //     id:34,
    //     service:"sus",
    //     product:"sus",
    //     category:"sus",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate1.jpeg"
    // },

    // {
    //     id:35,
    //     service:"sus",
    //     product:"sus",
    //     category:"sus",
    //     image:"assets/website-images/Industrial-PEB-Projects/peb1.jpg"
    // },
  
];
export default ProductsData;