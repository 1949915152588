
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { IoArrowForward } from "react-icons/io5";
// import { IoArrowBack, IoTrendingUpSharp } from "react-icons/io5";
// import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";
// import { BiCheckShield } from "react-icons/bi";

// import { FaStar, FaChevronRight, FaChevronLeft, FaUserAlt, FaMapMarkerAlt, FaWallet, FaRegBuilding } from "react-icons/fa";


import client1 from './../Images/HINDALCO.png';
import client2 from './../Images/gcc-hotel-and-club.png';
import client3 from './../Images/HP.jpg';
import client4 from './../Images/indian-oil.jpg';
import client5 from './../Images/ltgrouplogo.jpg';
import client6 from './../Images/platinum.jpg';
import client7 from './../Images/Raheja_Universal.jpg';
import client8 from './../Images/sail.png';

import client10 from './../Images/logo/british-columbia.png';
import client11 from './../Images/logo/caribbean-export-development-agency.png';
import client12 from './../Images/logo/bkpm.png';
import client13 from './../Images/logo/cyprus.jpg';
import client14 from './../Images/logo/san-antonio-logo.png';
import client15 from './../Images/logo/bkpm.png';


export default function Clients() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        pauseOnHover: false,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 7,
        autoplayHoverPause:true,
        slidesToScroll: 1,
        // prevArrow: <PrevButton />,
        // nextArrow: <NextButton />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,

                }
            }

        ],
        beforeChange: (current, next) => {
            console.log(next);
        }
    };
    return (

        <div className="w-100 over-x">

            <Slider {...settings}>

                <div className="item slide py-3 px-2 client-h">
                    <div className="carousel-img">

                        <img src={client10} className="img-responsive portfolio-image"
                            alt="" />

                    </div>
                </div>
                <div className="item slide py-3 px-2 client-h">
                    <div className="carousel-img">

                        <img src={client12} className="img-responsive portfolio-image"
                            alt="" />

                    </div>
                </div>
                <div className="item slide py-3 px-2 client-h">
                    <div className="carousel-img">

                        <img src={client13} className="img-responsive portfolio-image"
                            alt="" />

                    </div>
                </div>


                <div className="item slide py-3 px-2 client-h">
                    <div className="carousel-img">

                        <img src={client14} className="img-responsive portfolio-image"
                            alt="" />

                    </div>
                </div>
                <div className="item slide py-3 px-2 client-h">
                    <div className="carousel-img">

                        <img src={client15} className="img-responsive portfolio-image"
                            alt="" />

                    </div>
                </div>
                <div className="item slide py-3 px-2 client-h">
                    <div className="carousel-img">

                        <img src={client10} className="img-responsive portfolio-image"
                            alt="" />

                    </div>
                </div>
                <div className="item slide py-3 px-2 client-h">
                    <div className="carousel-img">

                        <img src={client11} className="img-responsive portfolio-image"
                            alt="" />

                    </div>
                </div>
                <div className="item slide py-3 px-2 client-h">
                    <div className="carousel-img">

                        <img src={client12} className="img-responsive portfolio-image"
                            alt="" />

                    </div>
                </div>
            </Slider>
        </div>
    );
}